<script>
    export let technologies;
    import TechnologyBox from './TechnologyBox.svelte';
</script>

<div class='tech-boxes-wrap'>
    {#if technologies && technologies.length == 0}
        <p>Not available for this program.</p>
    {:else}
        {#each technologies as technology}
            <TechnologyBox icon={technology.icon} title={technology.label} technology={technology.technology} isDisabled={technology.isDisabled} link={technology.link} hidePill={technology.hidePill}  />
        {/each}
    {/if}
</div>

<style lang='scss'>

    .tech-boxes-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

</style>