<script>
    export let category;
    export let manufacturers;
    export let widgetURLs;
    export let index; // the cards position in the list
    import { onMount, createEventDispatcher } from 'svelte';
    import { selectedTechnology } from '../store';
    import formatRebateRate from '../utils/formatRebateRate';
    import icons from '../../utils/icons';

    let iconSrc = '';
    const { ALLICONS } = icons();
    let visibleManufacturerLinks = [];
    let allManufacturerLinks = [];
    let isExpanded = index === 0 ? true : false;
    const dispatch = createEventDispatcher();

    onMount(() => {
        iconSrc = ALLICONS[$selectedTechnology.toUpperCase()]
    });

    // respond to the widgetURLs prop changes
    $: {
        if(category.product_category_abbr == 'DR') {
            console.log('in responsive effect', widgetURLs)

        }
        if(widgetURLs && widgetURLs.preferred_widgets && widgetURLs.main_list) {
            if(category.product_category_abbr == 'DR') {
                console.log('passes first if check')

        }
            if(manufacturers.length > 0) {
                // if a user has filtered the manufacturer list, disregard the 'top_five' vs. 'main_list'
                // if there are more than 5 filtered manufacturers, show the first 5, then put the rest in the 'see all' modal
                // this avoids a situation where a user limits to 1 or 2 manufacturers and those manufacturers dont naturally appear
                // in the 'top_five' list, thus being hidden from display by default
                let allAvailableLinks = []

                widgetURLs.preferred_widgets.filter((manufacturer) => {
                    if(manufacturers.includes(manufacturer.name)) {
                        allAvailableLinks.push(manufacturer)
                    }
                });
                widgetURLs.main_list.filter((manufacturer) => {
                    if(manufacturers.includes(manufacturer.name)) {
                        allAvailableLinks.push(manufacturer)
                    }
                });
                visibleManufacturerLinks = allAvailableLinks.slice(0, 5);
                allManufacturerLinks = allAvailableLinks;
            }
            else {
                visibleManufacturerLinks = widgetURLs.preferred_widgets;
                allManufacturerLinks = widgetURLs.main_list;
            }
        }
    }

    function handleManufacturerClick(e, link, manufacturerName) {
        e.preventDefault();
        e.stopPropagation();// prevent closing card

        if(window.gtag && !window.location.origin.includes('localhost')) {
            window.gtag('event', 'manufacturer_link_click', {
                'event_label': manufacturerName,
                'event_category': 'user_interaction',
                'non_interaction': true,
                'send_to': 'G-CYWFEE2MY3'
            });
        }
        window.open(`${link}`, '_blank');
    }

    function handleAllManufacturersClick() {
        dispatch('see-all', {
            links: allManufacturerLinks,
            category: category.product_category_name
        })
    }

</script>

<div class='results-card' class:expanded={isExpanded} on:click={() => isExpanded = !isExpanded}>
    <div class='results-card-meta-details'>
        <div class='results-card-title'>
            <div class='left-border'></div>
            <img src={iconSrc} alt='Technology Icon' />
            <p>{category.product_category_name}</p>
        </div>
        {#if $selectedTechnology.toUpperCase() === 'EV'}
            <div class='ev-rebate-badges'>
                {#if category.residential}
                    <span class='ev-residential-badge'>Residential</span>
                {/if}
                {#if category.non_residential}
                    <span class='ev-nonresidential-badge'>Non-Residential</span>
                {/if}
            </div>
        {:else}
            <span>{formatRebateRate(category)}</span>
        {/if}
    </div>

    {#if isExpanded}
    <div class='manufacturer-links'>
        <p class='manufacturer-links-title'>Visit these manufacturers to see qualified products for this category and get exact rebate amounts.</p>
        <div class='manufacturer-links-flex-wrap'>
            <div class='links-list'>
                <!-- if no manufacturer links -->
                {#if visibleManufacturerLinks.length === 0 && allManufacturerLinks.length === 0}
                <span>None.</span>
                {/if}
                 <!-- prioritized links links -->
                {#each visibleManufacturerLinks as manufacturerLink}
                <button class='manufacturer-link' on:click={(e) => handleManufacturerClick(e, manufacturerLink.widget_url, manufacturerLink.name)}>
                    {manufacturerLink.name}
                    {#if manufacturerLink.is_sponsored}
                        <span  style='margin-left: 2px;'>*</span>
                    {/if}
                </button>
                {/each}
            </div>
            {#if visibleManufacturerLinks.length < allManufacturerLinks.length}
            <button class='see-all-btn' on:click={handleAllManufacturersClick}>
                See All
            </button>
            {/if}
        </div>
    </div>
    {/if}
    <div class='card-expand-wrap'>
        <span class='card-expand__product-label'>Products</span>
        <span><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 12 15 18 9"></polyline></svg></span>
    </div>
</div>

<style lang='scss'>

    .results-card {
        border: 1px solid #ddd;
        margin-bottom: 15px;
        padding: 15px 25px 25px 25px;
        background-color: white;
        border-radius: 10px;
        position: relative;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
        transform: scale(1);
        transition: all 250ms ease;
        cursor: pointer;
        font-family: 'IBM Plex Serif';
        font-weight: 400;

        &.expanded {
            transform: scale(1.02);
            padding-bottom: 40px;

            .card-expand__product-label {
                display: none;
            }

            svg {
                transform: rotate(180deg);
                transition: all 300ms ease;
            }
        }

        .card-expand__product-label {
            display: block;
        }

        svg {
            transform: rotate(0);
            transition: all 300ms ease;
        }

        .results-card-meta-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .results-card-title {
                display: flex;
                align-items: center;

                p {
                    font-size: 1.15rem;
                    font-weight: 400;
                }
            }

            span {
                font-size: 0.85rem;
                font-style: italic;
                width: 30%;
                font-weight: 400;
                text-align: right;
            }

            .ev-rebate-badges {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 25%;

                .ev-residential-badge, .ev-nonresidential-badge {
                    border-radius: 25px;
                    display: block;
                    color: #004444;
                    padding: 0 5px;
                    text-align: center;
                    min-width: 50%;
                    margin-bottom: 5px;
                }
                .ev-residential-badge {
                    border: 1px solid #6fb98f;
                }
                .ev-nonresidential-badge {
                    border: 1px solid #dba727;
                }
            }
        }

        .manufacturer-links {

            .manufacturer-links-title {
                font-style: italic;
                margin-top: 1em;
            }

            .manufacturer-links-flex-wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 15px;
                min-width: 575px;

                .links-list {
                    width: 80%;
                }
            }

            .manufacturer-link {
                margin-top: 5px;
                margin-bottom: 0;
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
                background-color: #2C7873;
                background-image: none;
                border: none;
                border-radius: 7px;
                margin-left: 10px;
                cursor: pointer;
                padding-top: 5px;
                padding-bottom: 5px;
                color: white;
                width: 105px;
                max-width: 105px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                &:hover {
                    background-color: #1c4e4b;
                }
            }

            .see-all-btn {
                margin-top: 5px;
                background: transparent;
                border: none;
                color: #2C7873;
                cursor: pointer;

                &:hover {
                    color: #1c4e4b;
                }
            }
        }

        .left-border {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: #dba727;
            width: 10px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        img {
            width: 30px;
            height: auto;
            margin-right: 10px;
        }

        p {
            margin: 0;
        }

        .card-expand-wrap {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            .card-expand__product-label {
                font-size: 12px;
                font-style: italic;
                height: 16px;
                margin-bottom: -5px;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .results-card {
            .results-card-meta-details {
                flex-direction: column;
                margin-bottom: 15px;

                span {
                    width: 100%;
                    margin-top: 10px;
                    text-align: center;
                }
            }

            .manufacturer-links {
                .manufacturer-links-title {
                    text-align: center;
                }

                .manufacturer-links-flex-wrap {
                    flex-direction: column;
                    min-width: unset;

                    .links-list {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .manufacturer-link {
                            width: unset;
                            max-width: unset;
                        }
                    }
                }
            }
        }
    }

</style>