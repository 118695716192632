<script>
    import { onMount, onDestroy } from 'svelte';
    import {debounce} from 'debounce';
    import { getStateAbbv, getIsAStateAbbv } from '../../statelookup.js';
    import { selectedUtility, availableTerritories, navigateToStep } from '../store';
    import ExternalIcon from './icons/ExternalIcon.svelte';
    import Modal from './Modal.svelte';

    let searchTerm = '';
    let searchResults = []; // default structure of search results
    // flags for managing search results state
    let hasResults = false;
    let isResultsBoxOpen = false;
    let isLoading = false;
    let hasSearched = false;
    let showRedirectModal = false;

    onMount(() => {
        const element = document.querySelector('.searchbar-wrap');
        document.addEventListener('click', (e) => {
            var isClickInside = element.contains(e.target);

            if (!isClickInside) {
                isResultsBoxOpen = false;
            }
        })
    });

    onDestroy(() => {
        const element = document.querySelector('.searchbar-wrap');
        document.removeEventListener('click', (e) => {
            var isClickInside = element.contains(e.target);

            if (!isClickInside) {
                isResultsBoxOpen = false;
            }
        })
    });

    const debouncedSearch = debounce(search, 1000);

    async function search() {

        // resets
        isResultsBoxOpen = true;
        isLoading = true;
        hasSearched = false;
        hasResults = true;
        searchResults = [];

        const response = await fetch(`${API_ENDPOINT_V2}/utilities/search?q=${searchTerm}`);
        const data = await response.json();

        if(data.utilities && Object.keys(data.utilities).length > 0) {
            const states = Object.keys(data.utilities);

            states.forEach((state) => {
                const utilList = data.utilities[state];
                if(utilList) {
                    utilList.forEach((util) => {
                        // add state abbr. after each result
                        if(!util.name.includes(`(${util.state})`)) {
                            util.name = `${util.name} (${util.state})`;
                        }
                        searchResults.push(util);
                    })
                }
            });
        }

        if(searchResults.length === 0) {
            hasResults = false;
        }

        isLoading = false;
        hasSearched = true;
    }

    //
    function isUtilityInDefinedTerritories(utility) {

        // if the user has not defined any territories
        if(!$availableTerritories || $availableTerritories.length === 0) {
            return true;
        }
        // else, the user has specified territories
        else {
            const state = utility.state;
            let isExternal = true; // is the utility outside of specified territories

            $availableTerritories.forEach((territory) => {
                const territoryState = territory.value.split('-')[1];

                if(state.toUpperCase() === territoryState.toUpperCase()) {
                    isExternal = false;
                }
            });
            return !isExternal;
        }
    }

    function selectUtility(utility) {

        // if it is an external utility, show a confirmation and then
        // redirect to UtilityGenius
        const isValidUtility = isUtilityInDefinedTerritories(utility);

        if(isValidUtility) {

            // modify its technology arrays
            let downTechs = new Set;
            let midTechs = new Set;

            for(let i = 0; i < utility.downstream_technologies.length; i ++) {
                const {technology} = utility.downstream_technologies[i];
                downTechs.add(technology);
            }
            for(let j = 0; j < utility.midstream_technologies.length; j ++) {
                const {technology} = utility.midstream_technologies[j];
                midTechs.add(technology);
            }

            utility.downstream_technologies = Array.from(downTechs);
            utility.midstream_technologies = Array.from(midTechs);

            selectedUtility.set(utility);
            navigateToStep('TECHNOLOGY');
        }
        else {
            showRedirectModal = true;
        }
    }

    function handleRedirect() {
        const slug = $selectedUtility.http_friendly_alias || $selectedUtility.eiauniq;
        window.open(`${UG_ENDPOINT}/utilities/${slug}`, '_blank');
        showRedirectModal = false;
    }

</script>

<div class='searchbar-wrap'>
    <input type='text' class='searchbar' bind:value={searchTerm} placeholder="Search ..." on:keyup={debouncedSearch} on:focus={() => isResultsBoxOpen = true} />
    {#if isResultsBoxOpen}
        <div class='results'>
            {#if searchTerm === ''}
                <div class='placeholder-text'>
                    <p>Zip / State / Province / Utility</p>
                </div>
            {:else if isLoading}
                <div class='placeholder-text'>
                    <p>Loading ...</p>
                </div>
            {:else if hasSearched && !hasResults}
                <div class='placeholder-text'>
                    <p>No Results.</p>
                </div>
            {:else}
            <div class='results-list'>
                {#if searchResults.length > 0}
                    <p>Results</p>
                    <ul>
                        {#each searchResults as utility}
                            <li on:click={() => selectUtility(utility)}>
                                {utility.name}
                                {#if !isUtilityInDefinedTerritories(utility)}
                                    <span>
                                        <ExternalIcon />
                                    </span>
                                {/if}
                            </li>
                        {/each}
                    </ul>
                {/if}
            </div>
            {/if}
        </div>
    {/if}
</div>
{#if showRedirectModal}
    <Modal width='50%' on:request-close={() => showRedirectModal = false}>
        <div class='ug-modal-body' slot='modal-content'>
            <h1>Heads Up!</h1>
            <div class='ug-modal-content'>
                <p>
                    You will be redirected to UtilityGenius to view this utility.
                    Do you wish to continue?
                </p>
                <div class='btn-wrap'>
                    <button class='cancel-btn' on:click={() => showRedirectModal = false}>Cancel</button>
                    <button class='continue-btn' on:click={handleRedirect}>Continue</button>
                </div>
            </div>
        </div>
    </Modal>
{/if}


<style lang='scss'>

    .searchbar-wrap {
        margin-top: 15px;
        position: relative;
        display: flex;
        justify-content: center;

        .searchbar {
            width: 100%;
            border-radius: 25px;
            position: relative;
            margin: 0;
            padding-left: 20px;
            padding-right: 20px;
            background-color: transparent;
            height: 42px;
            border: 1px solid #ddd;
            font-family: 'IBM Plex Serif';
            font-weight: 400;

            &:focus-visible {
                outline: 1px solid rgb(44, 120, 115);
                box-shadow: none;
            }
        }

        .results {
            width: 95%;
            height: 150px;
            background-color: white;
            position: absolute;
            bottom: -151px;
            left: 50%;
            transform: translateX(-50%);
            overflow-y: scroll;
            padding-left: 15px;
            padding-right: 15px;
            box-sizing: border-box;
            z-index: 99;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
            color: grey;
            box-shadow: 0 20px 25px -5px rgb(0, 0, 0, 0.1);
            border: 1px solid #f1f0f0;

            .placeholder-text {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                font-family: 'IBM Plex Serif';
                font-weight: 400;
            }

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background: lightgray;
                border-radius: 10px;
                height: 30px;
            }

            .results-list {
                p {
                    background: rgb(44, 120, 115);
                    margin-left: -15px !important;
                    margin-right: -15px !important;
                    color: white;
                    font-size: 20px;
                    padding-top: 3px;
                    padding-left: 15px;
                    padding-bottom: 3px;
                    margin: 0;
                    position: sticky;
                    top: 0;
                    font-family: 'IBM Plex Serif';
                    font-weight: 400;
                }
                ul {
                    list-style: none;
                    padding: 0;
                    margin-top: 10;
                    margin-bottom: 10px;

                    li {
                        margin-bottom: 10px;
                        cursor: pointer;
                        font-family: 'IBM Plex Serif';

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .highlight {
                    background-color: rgb(205, 221, 226);
                }
            }
        }
    }

    .ug-modal-body {
        padding-left: 1em;
        padding-right: 1em;

        h1 {
            font-family: 'Source Sans Pro';
            margin-top: 0.65em;
            margin-bottom: 0.5em;
        }

        .ug-modal-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding-left: 2em;
            padding-right: 2em;

            .btn-wrap {
                margin-top: 1em;

                .continue-btn, .cancel-btn {
                    cursor: pointer;
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
                    border: none;
                    border-radius: 7px;
                    padding: 10px;
                }

                .continue-btn {
                    background-color: #2C7873;
                    background-image: none;
                    color: white;
                    margin-left: 1em;
                }
            }
        }
    }

</style>