const data = [
  [
    'State',
    ''
  ],
  [
    'US-KY',
    {
      v: 42,
      f: ''
    }
  ],
  [
    'US-OH',
    {
      v: 43,
      f: ''
    }
  ],
  [
    'US-NJ',
    {
      v: 13,
      f: ''
    }
  ],
  [
    'US-MD',
    {
      v: 11,
      f: ''
    }
  ],
  [
    'US-IL',
    {
      v: 29,
      f: ''
    }
  ],
  [
    'US-NC',
    {
      v: 31,
      f: ''
    }
  ],
  [
    'US-VA',
    {
      v: 39,
      f: ''
    }
  ],
  [
    'US-IN',
    {
      v: 23,
      f: ''
    }
  ],
  [
    'US-PA',
    {
      v: 19,
      f: ''
    }
  ],
  [
    'US-TX',
    {
      v: 33,
      f: ''
    }
  ],
  [
    'US-AZ',
    {
      v: 11,
      f: ''
    }
  ],
  [
    'US-MS',
    {
      v: 33,
      f: ''
    }
  ],
  [
    'US-LA',
    {
      v: 14,
      f: ''
    }
  ],
  [
    'US-MN',
    {
      v: 20,
      f: ''
    }
  ],
  [
    'US-WI',
    {
      v: 37,
      f: ''
    }
  ],
  [
    'US-NY',
    {
      v: 5,
      f: ''
    }
  ],
  [
    'US-GA',
    {
      v: 30,
      f: ''
    }
  ],
  [
    'US-IA',
    {
      v: 10,
      f: ''
    }
  ],
  [
    'US-SC',
    {
      v: 24,
      f: ''
    }
  ],
  [
    'US-FL',
    {
      v: 44,
      f: ''
    }
  ],
  [
    'US-CA',
    {
      v: 36,
      f: ''
    }
  ],
  [
    'US-MO',
    {
      v: 35,
      f: ''
    }
  ],
  [
    'US-ID',
    {
      v: 4,
      f: ''
    }
  ],
  [
    'US-WA',
    {
      v: 6,
      f: ''
    }
  ],
  [
    'US-OK',
    {
      v: 21,
      f: ''
    }
  ],
  [
    'US-MI',
    {
      v: 28,
      f: ''
    }
  ],
  [
    'US-NE',
    {
      v: 40,
      f: ''
    }
  ],
  [
    'US-AR',
    {
      v: 27,
      f: ''
    }
  ],
  [
    'US-SD',
    {
      v: 38,
      f: ''
    }
  ],
  [
    'US-MA',
    {
      v: 2,
      f: ''
    }
  ],
  [
    'US-NH',
    {
      v: 8,
      f: ''
    }
  ],
  [
    'US-OR',
    {
      v: 9,
      f: ''
    }
  ],
  [
    'US-CO',
    {
      v: 17,
      f: ''
    }
  ],
  [
    'US-NM',
    {
      v: 24,
      f: ''
    }
  ],
  [
    'US-ME',
    {
      v: 15,
      f: ''
    }
  ],
  [
    'US-MT',
    {
      v: 18,
      f: ''
    }
  ],
  [
    'US-WY',
    {
      v: 31,
      f: ''
    }
  ],
  [
    'US-UT',
    {
      v: 41,
      f: ''
    }
  ],
  [
    'US-RI',
    {
      v: 1,
      f: ''
    }
  ],
  [
    'US-CT',
    {
      v: 3,
      f: ''
    }
  ],
  [
    'US-ND',
    {
      v: 45,
      f: ''
    }
  ],
  [
    'US-DE',
    {
      v: 22,
      f: ''
    }
  ],
  [
    'US-HI',
    {
      v: 7,
      f: ''
    }
  ],
  [
    'US-DC',
    {
      v: 15,
      f: ''
    }
  ],
  [
    'US-NV',
    {
      v: 26,
      f: ''
    }
  ],
  [
    'US-AL',
    {
      v: 46,
      f: ''
    }
  ],
  [
    'US-TN',
    {
      v: 46,
      f: ''
    }
  ],
  [
    'US-VT',
    {
      v: 46,
      f: ''
    }
  ],
  [
    'US-WV',
    {
      v: 46,
      f: ''
    }
  ],
  [
    'US-AK',
    {
      v: 46,
      f: ''
    }
  ],
  [
    'US-KS',
    {
      v: 46,
      f: ''
    }
  ]
]

export default data;