<svelte:head>
    {#if window && !window.gtag}
        <!-- Global site tag (gtag.js) - Google Analytics -->
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-CYWFEE2MY3"></script>
        <script>
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('set', 'linker', { 'domains': [ window.location.href ] })
            gtag('js', new Date());
            gtag('config', 'G-CYWFEE2MY3');
        </script>
    {/if}
	<link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap" rel="stylesheet" />
	<link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
</svelte:head>

<script>
    export let widgetID;

    import { onMount } from 'svelte';
    import {
        page,
        widgetInstance,
        widgetToken,
        availableTechnologies,
        categoriesByTechnology,
        availableManufacturers,
        availableTerritories,
        sponsorshipSrc,
        selectedStream,
        selectedTechnology,
        selectedUtility,
        selectedRegion,
        sessionTimestamp
    } from './store';
    import HomeIcon from './components/icons/HomeIcon.svelte';
    import BackIcon from './components/icons/BackIcon.svelte';
    import Home from './pages/Home.svelte';
    import Location from './pages/Location.svelte';
    import Technology from './pages/Technology.svelte';
    import Results from './pages/Results.svelte';
    import WidgetNotFound from './pages/WidgetNotFound.svelte';

    import { lookup } from '../statelookup';

    let widgetNotFound = false;
    let currentPage;
    page.subscribe((newVal) => {
        currentPage = newVal;
    });

    // mapping of components to routes
    const components = {
        'MAP': Home,
        'LOCATION': Location,
        'TECHNOLOGY': Technology,
        'RESULT': Results
    }

    onMount(() => {
        checkPrepopulateStatus();
        getWidgetInstance();
    });

    // checks to see if the widget should skip straight to the final screen based on query params
    // example search: /?populate=true&tech=hvac&stream=midstream&util=04226-NY
    async function checkPrepopulateStatus() {

        let queryParams = window.location.href.split('?');
        queryParams.shift();

        if(queryParams.length === 0) {
            return false;
        }

        queryParams = queryParams[0].split('&');

        const hasPopulateFlag = queryParams.find((param) => param === 'populate=true');

        if(hasPopulateFlag) {

            let technology = queryParams.find((param) => param.includes('tech'));
            let stream = queryParams.find((param) => param.includes('stream'));
            let utilityEiauniq = queryParams.find((param) => param.includes('util'));

            technology = !technology ? 'LED' : technology.split('=')[1]
            stream = !stream ? 'downstream' : stream.split('=')[1]; // should this change if no stream? TODO
            utilityEiauniq = !utilityEiauniq ? '04226-NY' : utilityEiauniq.split('=')[1];

            const response = await fetch(`${API_ENDPOINT_V2}/utilities/${utilityEiauniq}/detail`);
            const utility = await response.json()

            const stateCode = `${utility.country}-${utility.state}`;
            const fullState = lookup(stateCode);

            selectedStream.set(stream);
            selectedTechnology.set(technology);
            selectedUtility.set(utility);
            selectedRegion.set(fullState);
            page.set('RESULT');
        }
    }

    async function getWidgetInstance() {
        const response = await fetch(`${UG_ENDPOINT}/api/widgets/getWidget?id=${widgetID}`);
        const data = await response.json();

        if(response.status === 500) {
            console.log('error: ', data);
            widgetNotFound = true;
        }
        else {
            console.log('widget data: ', data);
            widgetToken.set(widgetID);
            widgetInstance.set(data.data);
            availableTechnologies.set(data.data.technologies);
            categoriesByTechnology.set(data.data.categoriesByTechnology);
            sponsorshipSrc.set(data.data.imageSource);
            availableManufacturers.set(data.data.manufacturers);
            sessionTimestamp.set((new Date()).getTime() / 1000)

            // if the user has defined specific territories, make sure they're listed alphabetically
            if(data.data.territories && data.data.territories.length > 0) {

                let alphabeticalTerritories = [];
                let allTerritoryNames = data.data.territories.map((territory) => territory.label);
                allTerritoryNames.sort();
                allTerritoryNames.forEach((territoryLabel) => {
                    const territoryRecord = data.data.territories.find((territory) => territory.label === territoryLabel);
                    if(territoryRecord){
                        alphabeticalTerritories.push(territoryRecord);
                    }
                });

                availableTerritories.set(alphabeticalTerritories);
            }
            else {
                availableTerritories.set([]);
            }

            fetch('https://app-release.utilitygenius.com/api/widgets/heartbeat', {
                method: 'POST',
                body: JSON.stringify({
                    widget: {
                        ...data.data,
                        id: widgetID
                    }
                })
            })
        }
    }

    // if there is a sponsorship link, include the click event handler
    function bindSponsorshipLink(element) {
        if($widgetInstance.imageDestination && $widgetInstance.imageDestination !== '') {
            element.addEventListener('click', function() {
                window.open(`${$widgetInstance.imageDestination}`, '_blank');
            });
        }
        return {
            destroy() {
                element.removeEventListener('click', function() {
                    window.open(`${$widgetInstance.imageDestination}`, '_blank');
                });
            }
        }
    }


</script>

<main class='ug_large_widget_wrapper'>
    {#if widgetNotFound}
        <WidgetNotFound {widgetID} />
    {:else if $widgetInstance}
        {#if currentPage !== 'MAP'}
            <div class='back-actions'>
                <BackIcon />
                <div class='divider'></div>
                <HomeIcon />
            </div>
        {/if}
        <svelte:component this={components[currentPage]} />
    {/if}
    {#if $sponsorshipSrc && $sponsorshipSrc !== ''}
        <div class='sponsorship-wrap'>
            <img
                class:has-link={$widgetInstance.imageDestination && $widgetInstance.imageDestination !== ''}
                src={$sponsorshipSrc}
                alt='Sponsorship Logo'
                use:bindSponsorshipLink
            />
        </div>
    {/if}
    {#if $widgetInstance && !$widgetInstance.hide_logo}
        <div class='powered-by-wrap'>
            <span>Powered By:</span>
            <a href='https://app.utilitygenius.com' target='_blank' rel='noreferrer'>
                <img src='https://master.dhlixqah5pee.amplifyapp.com/images/UG_logo_tm_2022.png' alt='UG Logo' />
            </a>
        </div>
    {/if}
</main>

<style lang='scss'>

    .ug_large_widget_wrapper {
        width: 100%;
        max-width: 1080px;
        min-height: 500px;
        border: 1px solid #ddd;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        background-color: #F3F9F6;
        padding: 1em;
        box-sizing: border-box;
        border-radius: 7px;
        position: relative;
        overflow: hidden;

        // globals
        font-family: 'IBM Plex Serif',Georgia,"Times New Roman",serif !important;

        h1, h2, h3, h4, h5, span {
            font-family: 'Source Sans Pro', sans-serif !important;
            font-weight: 400;
        }

        .back-actions {
            display: flex;
            align-items: center;
            margin-top: 15px;
            margin-bottom: -5px;

            .divider {
                width: 1px;
                height: 10px;
                background-color: rgb(44, 120, 115);
                margin-left: 5px;
            }
        }

        .sponsorship-wrap {
            height: 70px;
            width: 800px;
            margin: 15px auto 0 auto;

            img {
                border-radius: 7px;
                box-shadow: 4px 4px 10px rgba(0,0,0,0.2);
                width: 800px;
                height: 70px;
                object-fit: fill;

                &.has-link {
                    cursor: pointer;
                }
            }
        }

        .powered-by-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 10px;
            font-family: 'IBM Plex Serif';
            font-size: 12px;

            img {
                margin-left: 5px;
                width: 110px;
            }
        }
    }

    @media screen and (max-width: 1079px) {
        .widget {
            width: 95%;

            .sponsorship-wrap {
                max-width: 800px;
                width: 100%;

                img {
                    max-width: 800px;
                    width: 100%;
                }
            }
        }
    }

</style>