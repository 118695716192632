const ALLICONS_DARK = Object.freeze({
    CTRL: 'https://utility-genius.s3.amazonaws.com/icons/dark/ctrl.png',
    CTRLCUSTOM: 'https://utility-genius.s3.amazonaws.com/icons/dark/ctrl.png',
    LED: 'https://utility-genius.s3.amazonaws.com/icons/dark/led.png',
    LEDCUSTOM: 'https://utility-genius.s3.amazonaws.com/icons/dark/led.png',
    EV: 'https://utility-genius.s3.amazonaws.com/icons/dark/ev.png',
    HVAC: 'https://utility-genius.s3.amazonaws.com/icons/dark/hvac.png',
    HVACCUSTOM: 'https://utility-genius.s3.amazonaws.com/icons/dark/hvac.png',
    VFD: 'https://utility-genius.s3.amazonaws.com/icons/dark/vfd.png',
    VFDCUSTOM: 'https://utility-genius.s3.amazonaws.com/icons/dark/vfd.png',
    FAN: 'https://utility-genius.s3.amazonaws.com/icons/dark/fan.png',
    FANCUSTOM: 'https://utility-genius.s3.amazonaws.com/icons/dark/fan.png',
    REFRIG: 'https://utility-genius.s3.amazonaws.com/icons/dark/refrig.png',
    REFRIGCUSTOM: 'https://utility-genius.s3.amazonaws.com/icons/dark/refrig.png',
    HORT: 'https://utility-genius.s3.amazonaws.com/icons/dark/hort.png',
    HORTCUSTOM: 'https://utility-genius.s3.amazonaws.com/icons/dark/hort.png',
    OTHER: 'https://utility-genius.s3.amazonaws.com/icons/dark/other.png',
    OTHERCUSTOM: 'https://utility-genius.s3.amazonaws.com/icons/dark/other.png',
    FUTURE: 'https://utility-genius.s3.amazonaws.com/icons/dark/future.png',
    FUTURECUSTOM: 'https://utility-genius.s3.amazonaws.com/icons/dark/future.png',
    SOLAR: 'https://utility-genius.s3.amazonaws.com/icons/dark/solar.png',
    SOLARCUSTOM: 'https://utility-genius.s3.amazonaws.com/icons/dark/solar.png',
    BIZMRKT: "https://utility-genius.s3.amazonaws.com/icons/grey/bizmrkt.png",
})

const ALLICONS = Object.freeze({
    CTRL:    'https://utility-genius.s3.amazonaws.com/icons/ctrl.png',
    CTRLCUSTOM:   'https://utility-genius.s3.amazonaws.com/icons/ctrl.png',
    LED:   'https://utility-genius.s3.amazonaws.com/icons/led.png',
    LEDCUSTOM:   'https://utility-genius.s3.amazonaws.com/icons/led.png',
    EV: 'https://utility-genius.s3.amazonaws.com/icons/ev.png',
    HVAC:   'https://utility-genius.s3.amazonaws.com/icons/hvac.png',
    HVACCUSTOM:   'https://utility-genius.s3.amazonaws.com/icons/hvac.png',
    VFD:   'https://utility-genius.s3.amazonaws.com/icons/vfd.png',
    VFDCUSTOM:   'https://utility-genius.s3.amazonaws.com/icons/vfd.png',
    FAN:   'https://utility-genius.s3.amazonaws.com/icons/fan.png',
    FANCUSTOM:   'https://utility-genius.s3.amazonaws.com/icons/fan.png',
    REFRIG:    'https://utility-genius.s3.amazonaws.com/icons/refrig.png',
    REFRIGCUSTOM:    'https://utility-genius.s3.amazonaws.com/icons/refrig.png',
    HORT:   'https://utility-genius.s3.amazonaws.com/icons/hort.png',
    HORTCUSTOM:   'https://utility-genius.s3.amazonaws.com/icons/hort.png',
    OTHER:    'https://utility-genius.s3.amazonaws.com/icons/other.png',
    OTHERCUSTOM:    'https://utility-genius.s3.amazonaws.com/icons/other.png',
    FUTURE:  'https://utility-genius.s3.amazonaws.com/icons/future.png' ,
    FUTURECUSTOM:  'https://utility-genius.s3.amazonaws.com/icons/future.png' ,
    SOLAR:  'https://utility-genius.s3.amazonaws.com/icons/solar.png' ,
    SOLARCUSTOM: 'https://utility-genius.s3.amazonaws.com/icons/solar.png',
    BIZMRKT: "https://utility-genius.s3.amazonaws.com/icons/bizmrkt.png", 

})
const icons = () => {

    return { ALLICONS, ALLICONS_DARK }
}

export default icons;