<svelte:head>
    <script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script>
</svelte:head>
<script>
    export let activeRegion = 'usa'; // default
    export let selectedLocation = undefined; // a selected location (e.g. 'US-PA') can optionally be passed in
    import { createEventDispatcher } from 'svelte';
    import { lookup } from '../../statelookup';
    import { availableTerritories, mapPositionCache, selectedRegion, navigateForward } from '../store';
    import USAMapData from '../utils/MapData_US';
    import CanadaMapData from '../utils/MapData_Canada';
    import Loader from '../components/Loader.svelte';

    let loading = true;
    let showingFullMap = true;
    let filteredUSAMapData = [];
    let filteredCanadaMapData = [];
    let isFilteredData = false;
    let mapDrawn = false; // a flag to keep track of when the map is initially rendered
    const dispatch = createEventDispatcher();

    $: {
        console.log('aval terr: ', $availableTerritories)
        if($availableTerritories.length > 0) {
            isFilteredData = true;
            let availableUSA = [];
            let availableCA = [];

            $availableTerritories.forEach((territory) => {
                let foundUSATerritory = USAMapData.find((dataPoint) => {
                    return dataPoint[0] === territory.value;
                });
                let foundCATerritory = CanadaMapData.find((dataPoint) => {
                    return dataPoint[0] === territory.value;
                });

                if(foundUSATerritory) {
                    availableUSA = [ ...availableUSA, foundUSATerritory ];
                }
                if(foundCATerritory) {
                    availableCA = [ ...availableCA, foundCATerritory ];
                }
            })
            console.log('filteredTerritories: ', availableUSA, availableCA);
            filteredUSAMapData = [ [ 'State', '' ], ...availableUSA ];
            filteredCanadaMapData = [ [ 'State', '' ], ...availableCA ];
        }
        else {
            filteredUSAMapData = USAMapData;
            filteredCanadaMapData = CanadaMapData;
        }
    }

    // svelte doesn't have a designated google charts library, and the generic 'google-charts' package on npm
    // does not support GeoChart.  so, need to use the vanilla js version of google charts

    // the timing is weird on when svelte loads a component, when svelte:head is included, and when the browser
    // loads the window.  anonymous async function to not initialize the chart until the global window.google variable
    // is defined
    (async () => {
        console.log('waiting for script load 3 --')
        while(!window.google && !(window.google && window.google.charts)) {
            await new Promise(resolve => setTimeout(resolve, 100)); // checks existence every 100ms
        }
        loadChart();
    })();

    // whenever the activeRegion prop changes
    $: {
        // need to make sure that the map has already been drawn when reacting to this 'activeRegion' change
        // else, it fires at the same time that the above async block fires (reactive 'window.google'), which causes the map to
        // double render. this becomes a problem when using clip-path and navigating back from the location page
        if(window.google && mapDrawn) {
            // this is here only to have 'activeRegion' as a reactive dependency
            const newRegion = activeRegion === 'usa' ? 'US' : 'CA';
            loadChart()
        }
    }

    function loadChart() {
        console.log('LOAD -------')
        google.charts.load('current', {
            'packages':['geochart'],
            'mapsApiKey': 'AIzaSyDuY3lwN8WFuuA0G_x0rADDK9FeV-ZSWmU'
        });

        let newRegion = activeRegion === 'usa' ? 'US' : 'CA';
        // if the user has specified only regions in canada, show that region by default
        if(isFilteredData && filteredCanadaMapData.length > 1 && filteredUSAMapData.length === 1) {
            newRegion = 'CA';
        }
        google.charts.setOnLoadCallback(() => drawRegionsMap(newRegion));
    }

    function loadChartWithCacheReset() {
        loading = true;
        mapPositionCache.set({});
        loadChart();
    }

    function drawRegionsMap(region) {

        let dataSet = [[
            'State',
            ''
        ]];
        let mapWidth = 1000;

        // if passed in a specific location
        if(selectedLocation) {
            console.log('selected location is: ', selectedLocation)
            let dataPoint = []

            if(selectedLocation.includes('US')) {
                USAMapData.map((item) => {
                    if(item[0] === selectedLocation) {
                        dataPoint = item;
                    }
                });

                dataSet.push(dataPoint)
            }
            else {
                CanadaMapData.map((item) => {
                    if(item[0] === selectedLocation) {
                        dataPoint = item;
                    }
                })
                dataSet.push(dataPoint)
                 // manually update the 'region' parameter because thats driven by the toggle,
                 // and that doesnt exist when we individually select a state
                region = 'CA';
            }

            mapWidth = window.innerWidth < 1080 ?  window.innerWidth * 0.463 : 1080 * 0.463; // hard coded magic number
        }
        // else, show all states / regions
        else {
            dataSet = region === 'US' ? filteredUSAMapData : filteredCanadaMapData;
            mapWidth = window.innerWidth < 768 ? window.innerWidth - 100 : 700;
        }

        var data = google.visualization.arrayToDataTable(dataSet);

        // if on a location page (user selected a location), only show that location rather than the full map
        let mapRegion = selectedLocation ? selectedLocation : region;
        if(region === 'US' && filteredUSAMapData.length === 2) {
            mapRegion = filteredUSAMapData[1][0];
        }
        // google geocharts doesn't support setting its 'region' option to a specific canadian province
        // so if the filtered data is just one province, or the user clicked a canadian province and is
        // viewing on the location page, override the mapRegion and just set it to all of canada
        else if(region === 'CA' && filteredCanadaMapData.length === 2) {
            mapRegion = 'CA';
        }
        if(selectedLocation && selectedLocation.includes('CA-')) {
            mapRegion = 'CA';
        }

        var options = {
            region: mapRegion,
            resolution: 'provinces',
            colors: ['#004444', '#84dbaa'],
            backgroundColor: 'transparent',
            legend: 'none',
            width: mapWidth
        };

        const chart = new google.visualization.GeoChart(document.getElementById('map_anchor'));

        google.visualization.events.addListener(chart,'select', function(e){
            const locationCode = data.getValue(chart.getSelection()[0].row, 0)
            const location = lookup(locationCode);
            selectedRegion.set(location);

            // remove all map svg's prior to navigation - this is important for the potential clip-path generation
            const mapSVGs = document.querySelectorAll('#map_anchor svg');
            if(mapSVGs) {
                mapSVGs.forEach((el) => {
                    el.remove();
                })
            }
            navigateForward();
        });

        // if not on a location page (not selected a location) and there are user contstrained territories
        if(!selectedLocation && $availableTerritories.length > 1) {
            google.visualization.events.addListener(chart, 'ready', function() {
                console.log('--- generating map ---')
                generateLimitedTerritoryMap(mapRegion);
                mapDrawn = true;
            });
        }
        //
        else {
            google.visualization.events.addListener(chart, 'ready', function() {
                mapDrawn = true;
            });

            dispatch('calculated-width', {
                'shouldHideDropdown': false,
                'shouldHideRegionToggle': isFilteredData && !(filteredUSAMapData.length > 1 && filteredCanadaMapData.length > 1)
            });
        }

        loading = false;
        chart.draw(data, options);
    }

    function handleRegionClick(locationCode) {
        const location = lookup(locationCode);
        selectedRegion.set(location);
        navigateForward();
    }

    // dynamically create a clip path to only show the user defined territories
    function generateLimitedTerritoryMap(region) {
        const selectedTerritoryNodes = document.querySelectorAll('path:not([stroke="#dddddd"])');
        const mapAnchor = document.getElementById('map_anchor');

        // if either the map anchor or the map isn't there, quit
        if(!mapAnchor) {
            return;
        }

        if(mapAnchor) {
            const mapAnchorRect = mapAnchor.getBoundingClientRect();
            const map = mapAnchor.querySelector('svg');

            if(!map) {
                return;
            }
            const mapRect = map.getBoundingClientRect();

            // --- variable init ---

            // find the following points in relation to the selected territories
            // e.g. the lowest 'top' point that a selected territory touches
            let smallestTop = 0;
            let smallestLeft = 0;
            let biggestRight = 0;
            let biggestBottom = 0;
            // the coverage variables are the coordinates of the clip-path
            let coverageTop = 0;
            let coverageRight = 0;
            let coverageBottom = 0;
            let coverageLeft = 0;
            // extra data points needed for calculations
            let mapWidth = 0;
            let renderedMapWidthProportion = 0;
            // calculate a negative left margin, to horizontally center the visible map
            let marginTop;
            let marginLeft;

            if($mapPositionCache[region]) {
                smallestTop = $mapPositionCache[region].smallestTop;
                smallestLeft = $mapPositionCache[region].smallestLeft;
                biggestRight = $mapPositionCache[region].biggestRight;
                biggestBottom = $mapPositionCache[region].biggestBottom;
                coverageTop = $mapPositionCache[region].coverageTop;
                coverageRight = $mapPositionCache[region].coverageRight;
                coverageBottom = $mapPositionCache[region].coverageBottom;
                coverageLeft = $mapPositionCache[region].coverageLeft;
                mapWidth = $mapPositionCache[region].mapWidth;
                renderedMapWidthProportion = $mapPositionCache[region].renderedMapWidthProportion;
                marginTop = $mapPositionCache[region].marginTop;
                marginLeft = $mapPositionCache[region].marginLeft;
            }
            else {
                console.log('no cache')

                selectedTerritoryNodes.forEach((el) => {
                    if(el) {
                        let rect = el.getBoundingClientRect();
                        if(smallestTop === 0 || rect.top < smallestTop) {
                            smallestTop = rect.top;
                        }
                        if(smallestLeft === 0 || rect.left < smallestLeft ) {
                            smallestLeft = rect.left;
                        }
                        if(biggestRight === 0 || rect.right > biggestRight) {
                            biggestRight = rect.right;
                        }
                        if(biggestBottom === 0|| rect.bottom > biggestBottom) {
                            biggestBottom = rect.bottom;
                        }
                    }
                });
                // calculate the coordinates of the covering rectangle (build in a 20px buffer)
                coverageTop = (smallestTop - mapRect.top) - 20;
                coverageLeft = (smallestLeft - mapRect.left) - 20;
                coverageBottom = (mapRect.bottom - biggestBottom) - 20;
                coverageRight = (mapRect.right - biggestRight) - 20;
                mapWidth = biggestRight - smallestLeft;
                // proportion of the rendered visible map to the width of the container
                renderedMapWidthProportion = (mapWidth / mapAnchorRect.width) * 100;
            }

            // auto zoom a bit if the visible map is small
            let scale = mapWidth / 100;

            // make sure that when increasing the scale of the map, it does not become taller than the map_anchor
            // and overloap neighboring content
            const heightOfVisibleMap = (mapRect.bottom - coverageBottom) - (mapRect.top + coverageTop);
            let heightOfVisibleMapWithScale = heightOfVisibleMap * scale;
            const widthOfVisibleMap = (mapRect.right - coverageRight) - (mapRect.left + coverageLeft);
            let widthOfVisibleMapWithScale = widthOfVisibleMap * scale;
            let dontChangeHeight = false;

            while(heightOfVisibleMapWithScale > (mapRect.bottom - mapRect.top)) {
                scale -= 0.05;
                heightOfVisibleMapWithScale = heightOfVisibleMap * scale;
            }
            while(widthOfVisibleMapWithScale > (mapRect.right - mapRect.left - 275)) {
                dontChangeHeight = true;
                scale -= 0.05;
                widthOfVisibleMapWithScale = widthOfVisibleMap * scale;
            }

            while(heightOfVisibleMapWithScale < ((mapRect.bottom - mapRect.top) - 75) && !dontChangeHeight) {
                scale += 0.05;
                heightOfVisibleMapWithScale = heightOfVisibleMap * scale;
            }

            if(scale < 1) {
                scale = 1;
            }

            // console.log('width of visible scale2: ', widthOfVisibleMapWithScale)
            //     console.log('width2: ', (mapRect.right - mapRect.left - 275))
            // console.log('scale 2: ', scale)
            // console.log('mapRect: ', mapRect);
            // console.log('coerage TRBL: ', coverageTop, coverageRight, coverageBottom, coverageLeft)
            // console.log('height of visible map: ', heightOfVisibleMapWithScale)
            // console.log('first part: ', mapAnchorRect)
            // console.log('scale: ', scale)
            // console.log('proportion width:  ', renderedMapWidthProportion);

            if($mapPositionCache[region]) {
                marginTop = $mapPositionCache[region].marginTop;
                marginLeft = $mapPositionCache[region].marginLeft;
            }
            else {
                // marginTop = ((coverageTop * scale) - (mapRect.height - heightOfVisibleMapWithScale));
                marginTop = -coverageTop + 15;

                if(marginTop > 0) {
                   marginTop = (marginTop * -1) - 25;
                }

                if(marginTop < (mapRect.top * -1)) {
                    marginTop = mapRect.top * -1;
                }

                if(smallestLeft > 750) {
                    marginLeft = ((smallestLeft - mapRect.left) / 2) * -1;
                }
                if(mapWidth < 400) {
                    marginLeft = ((smallestLeft - mapRect.left) + 50) * -1;
                }
                // mobile overrides
                if(mapRect.left < 150 && mapRect.left > 0) {
                    marginLeft = (coverageLeft - mapAnchorRect.left) * -1;
                }
                if(mapRect.left <= 0) {
                    marginLeft = (coverageLeft - mapAnchorRect.left) * 0.5 * -1;
                }
            }
            // console.log('margin top: ', marginTop)
            // if the visible map is less that 50% of the map_anchor container,
            // do not show the entire map, only show the 'visible map' calculated above
            if(renderedMapWidthProportion * scale < 50) {
                showingFullMap = false;
            }
            else {
                showingFullMap = true;
            }

            // dispatch info to the parent component (only necessary for the home page)
            dispatch('calculated-width', {
                'shouldHideDropdown': !showingFullMap,
                'shouldHideRegionToggle': isFilteredData && !(filteredUSAMapData.length > 1 && filteredCanadaMapData.length > 1)
            });

            if(!$mapPositionCache[region]) {

                // caache a whole bunch of information, to prevent recalculating and to store the state of the 'map_anchor' coordinates
                // relative to the viewport on the initial load (making the map modifications throws everything off and it will only compound)
                const coordinates = {
                    smallestTop,
                    smallestLeft,
                    biggestRight,
                    biggestBottom,
                    coverageTop,
                    coverageRight,
                    coverageBottom,
                    coverageLeft,
                    mapWidth,
                    renderedMapWidthProportion,
                    mapRect,
                    marginLeft,
                    marginTop,
                }

                mapPositionCache.set({ ...$mapPositionCache, [region]: coordinates });
            }

            // if only showing a subsection of the map, create the css clip-path and assign to the map svg element
            if(!showingFullMap) {
                console.log('adding clip path')
                // there is a bug where if you click between the views really fast, the previous chart might not have been
                // fully destroyed. so when this function runs it runs on an 'almost destroyed' chart, leading to a chart width of
                // 0. the function re-runs on the 'ready' event of the new chart, but this leads to a bug where the chart is
                // available but the 'scale' is still 0. do a workaround check
               const style = `clip-path: polygon(
                                            ${coverageLeft}px ${coverageTop}px,
                                            calc(100% - ${coverageRight}px) ${coverageTop}px,
                                            calc(100% - ${coverageRight}px) calc(100% - ${coverageBottom}px),
                                            ${coverageLeft}px calc(100% - ${coverageBottom}px)
                                        );
                                        transform:scale(${scale === 0 ? 1 : scale});
                                        transform-origin: ${coverageLeft}px ${coverageTop}px;
                                        margin-left:${marginLeft};
                                        margin-top:${marginTop};`;

                map.style.cssText = style;
            }
            else {
                map.style.cssText=`max-height:400px;margin-top:15px;`;
            }
        }
    }
</script>

<svelte:window on:resize={loadChartWithCacheReset} />

{#if loading}
    <Loader />
{/if}
<div id='map_anchor' class="map-anchor { !showingFullMap ? 'ug-rounded' : ''}" style='position: relative;'></div>
{#if !showingFullMap}
<div id='territory-list-wrap'>
    <h2 class='select-territory-label'>Select A Territory</h2>
    <ul class='territory-list'>
        {#each $availableTerritories as territory}
            <li class='territory-list-item' on:click={() => handleRegionClick(territory.value)}>
                {territory.label}
            </li>
        {/each}
    </ul>
</div>
<svg style="visibility: hidden; position: absolute;" width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <defs>
        <filter id="round">
            <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
            <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
        </filter>
    </defs>
</svg>
{/if}


<style lang='scss'>

    .map-anchor {

        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        // background: blue;

        &.ug-rounded {
            filter:url("#round");
        }

       :global(path) {
           cursor: pointer;
       }

       :global(svg g.google-visualization-tooltip) {
         pointer-events: none;
       }
    }

    #territory-list-wrap {
        position: absolute;
        top: 10%;
        right: 10%;
        max-height: 375px;
        overflow-y: scroll;
        width: 250px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: inset 0px 0px 14px 3px rgba(0,0,0,0.05);
        border-radius: 7px;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background: lightgray;
            border-radius: 10px;
            height: 30px;
        }

        .select-territory-label {
            text-decoration: underline;
            font-weight: 500;
            margin-bottom: 0;
        }

        .territory-list {
            list-style: none;
            text-align: center;
            padding: 0;
            width: 90%;

            .territory-list-item {
                margin-bottom: 10px;
                background-color: #2C7873;
                background-image: none;
                color: white;
                font-family: 'IBM Plex Serif';
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
                border-radius: 7px;
                cursor: pointer;
                padding-top: 5px;
                padding-bottom: 5px;

                &:hover {
                    background-color: #225d59;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {

        #territory-list-wrap {
            display: none;
        }
    }

</style>
