<script>
    export let icon;
    export let title;
    export let technology;
    export let isDisabled;
    export let link;
    export let hidePill;

    import { selectedTechnology, navigateForward } from '../store';

    function handleTechBoxClick() {
        if(technology === "BIZMRKT") {
            if (isDisabled === true) {
                return;
            } else {
                console.log('in here: ', link)
                return (location.href = link);
            }
        }

        selectedTechnology.set(technology);
        navigateForward();
    }

</script>

<div class={isDisabled === true ? 'disabled-box' : 'tech-box' } on:click={handleTechBoxClick} >
    {#if technology === 'BIZMRKT' && !hidePill}
    <span class='ugB2BPill'>B2B</span>
    {/if}
    <img src={icon} alt='Icon' />
    <strong>{title}</strong>
</div>

<style lang='scss'>

    .tech-box {
        width: 300px;
        margin: 5px;
        border: 1px solid #ddd;
        border-radius: 7px;
        background-color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 5px;
        box-shadow: 6px 6px 10px rgba(39, 27, 27, 0.2);
        cursor: pointer;
        transform: scale(1);
        transition: all ease 250ms;
        font-family: 'IBM Plex Serif';
        position: relative;

        &:hover {
            transform: scale(1.03);
        }

        img {
            height: 60px;
            margin-bottom: 15px;
        }

        .ugB2BPill {
            position: absolute;
            right: 10px;
            top: 5px;
            background: #274371;
            color: white;
            padding: 0px 20px;
            font-size: 0.875rem;
            border-radius: 20px;
        }
    }

    .disabled-box {
        @extend .tech-box;
        cursor: not-allowed;
        color: lightgrey;

        &:hover{
            transform: none;
        }

        .ugB2BPill {
            position: absolute;
            right: 10px;
            top: 5px;
            background: #eaedeb;
            padding: 0px 20px;
            font-size: 0.875rem;
            border-radius: 20px;
            color: #d4d6d5;
        }
    }
</style>