<script>
    export let icon;
    export let title;
    export let technology;
    export let isDisabled;
    export let link;
    export let hidePill;

    import { selectedTechnology, navigateForward, selectedUtility } from '../store';

    function handleTechBoxClick() {
        if(technology === "BIZMRKT") {
            if (isDisabled === true) {
                return;
            } else {
                console.log('in here: ', link)
                return (location.href = link);
            }
        } else if(technology === 'MORE') {
          return window.open(`https://app.utilitygenius.com/utilities/${$selectedUtility.eiauniq}?utm_source=ugwidget&utm_medium=referral&utm_campaign=c1`, '_blank')
        }

        selectedTechnology.set(technology);
        navigateForward();
    }

</script>

<div class={isDisabled === true ? 'disabled-box' : 'tech-box' } on:click={handleTechBoxClick} >
  {#if technology == 'MORE'}
    <img src={icon} alt='Icon' />
    <strong style=''>
      Additional Measures
      <span>
        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
      </span>
    </strong>
  {:else}
    {#if technology === 'BIZMRKT' && !hidePill}
      <span class='ugB2BPill'>B2B</span>
    {/if}
    <img src={icon} alt='Icon' />
    <strong>{title}</strong>
  {/if}
</div>

<style lang='scss'>

    .tech-box {
        width: 300px;
        margin: 5px;
        border: 1px solid #ddd;
        border-radius: 7px;
        background-color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 5px;
        box-shadow: 6px 6px 10px rgba(39, 27, 27, 0.2);
        cursor: pointer;
        transform: scale(1);
        transition: all ease 250ms;
        font-family: 'IBM Plex Serif';
        position: relative;

        &:hover {
            transform: scale(1.03);
        }

        img {
            height: 60px;
            margin-bottom: 15px;
        }

        .ugB2BPill {
            position: absolute;
            right: 10px;
            top: 5px;
            background: #274371;
            color: white;
            padding: 0px 20px;
            font-size: 0.875rem;
            border-radius: 20px;
        }
    }

    .disabled-box {
        @extend .tech-box;
        cursor: not-allowed;
        color: lightgrey;

        &:hover{
            transform: none;
        }

        .ugB2BPill {
            position: absolute;
            right: 10px;
            top: 5px;
            background: #eaedeb;
            padding: 0px 20px;
            font-size: 0.875rem;
            border-radius: 20px;
            color: #d4d6d5;
        }
    }
</style>
