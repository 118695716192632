<script>
    import { onMount } from 'svelte';
    import Select from 'svelte-select';
    import Item from './StateDropdownItem.svelte';
    import { widgetInstance, selectedRegion, availableTerritories, navigateForward } from '../../store';
    import { lookup } from '../../../statelookup';
    import { usa_preferred, canada_preferred } from './data';

    let items;

    onMount(() => {
        $widgetInstance.countryPreference && $widgetInstance.countryPreference === 'canada' ? items = canada_preferred : items = usa_preferred;

        if($availableTerritories.length > 0) {
            const filteredItems = items.filter((territory) => {
                const isAvailable = $availableTerritories.find((t) => t.value === territory.value);

                if(isAvailable) {
                    return territory;
                }
            });

            items = filteredItems;
        }
    });

    const groupBy = (item) => {
        return item.value.includes('US') ? 'USA' : 'Canada';
    }
    const optionIdentifier = 'value';
    const getOptionLabel = (option) => option.label;

    function handleSelect(event) {
        const region = lookup(event.detail.value);
        selectedRegion.set(region);
        navigateForward();
	}

</script>

<div class='wrap'>
    <Select {items} {Item} {getOptionLabel} {optionIdentifier} {groupBy} on:select={handleSelect}
        inputStyles='cursor: pointer;' placeholder='Select Region ...' listPlacement='bottom' />
</div>

<style lang='scss'>

    .wrap {
        width: 100%;
        font-family: 'IBM Plex Serif';
        --borderRadius: 25px;
        --groupTitleFontSize: 20px;
        --groupTitleColor: white;
        --borderFocusColor: rgb(44, 120, 115);
    }

    :global(.listContainer) {
        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background: lightgray;
            border-radius: 10px;
            height: 30px;
        }
    }

    :global(.listGroupTitle) {
        background-color: rgb(44, 120, 115);
        position: sticky;
        top: 0;
        margin-left: 8px;
        height: 30px !important;
        line-height: 30px !important;
    }
</style>