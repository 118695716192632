<script>
    import { onMount } from 'svelte'
    import { selectedRegion, availableTechnologies, selectedStream, widgetToken, sessionTimestamp } from '../store';
    import Map from '../components/Map.svelte';
    import UtilityLocationCard from '../components/UtilityLocationCard.svelte';
    import StreamToggle from '../components/StreamToggle.svelte';
    import Loader from '../components/Loader.svelte';
    import Modal from '../components/Modal.svelte';
    import { getStateAbbv, getIsStateAbbvInUSA } from '../../statelookup';

    let utilData;
    let locationCode = ''; // the location code (e.g. 'US-PA') from the selectedRegion
    let locationFullName = '';
    let loading = true;
    let visibleUtilities = [];
    let allUtilities = [];
    let filteredUtilities = [];
    let allUtilitiesModalOpen = false;
    let forceVerticalLayout = false;

    onMount(() => {
        getLocation()

        if(window.gtag && !window.location.origin.includes('localhost')) {
            console.log("location viewed gtag event ---")
            window.gtag('event', 'location_viewed', {
                'event_label': $selectedRegion,
                'event_category': 'user_interaction',
                'non_interaction': true,
                'send_to': 'G-CYWFEE2MY3'
            });
        }
        try {
            fetch(`${UG_ENDPOINT}/api/widgets/setLastUsed`, {
                method: 'POST',
                body: JSON.stringify({
                    lastUsed: (new Date()).getTime() / 1000,
                    id: $widgetToken
                })
            })
            fetch(`${UG_ENDPOINT}/api/widgets/setJourney`, {
                method: 'POST',
                body: JSON.stringify({
                    timestamp: $sessionTimestamp,
                    id: $widgetToken,
                    step: 'location'
                })
            })
        } catch (err) {
            // dont stop anything
            console.error('error tracking last used')
        }

        // cannot rely on viewport width, the accurate metric is the width of the widget
        const widgetWidth = document.querySelector('.ug_large_widget_wrapper').clientWidth
        if (widgetWidth <= 899) {
            forceVerticalLayout = true;
        }
    });

    function compare( a, b ) {
        if (a.program_status == "Open" && b.program_status == "Open") {
            if (a.nonrescustomers >= b.nonrescustomers) {
                return -1;
            } else {
                return 1;
            }
        }
        if (a.program_status == "Open") {
            return -1;
        }
        if (b.program_status == "Open") {
            return 1;
        }
        if (a.program_status < b.program_status) {
            return -1;
        }
        if (a.program_status > b.program_status) {
            return 1;
        }
        return 0;
    }

    async function getLocation() {
        let initialLocation = $selectedRegion.replaceAll('-', ' ')
        let theLocation = getStateAbbv(initialLocation)

        const isUSALocation = getIsStateAbbvInUSA(theLocation[0].abbv);
        isUSALocation ? locationCode = `US-${theLocation[0].abbv}` : locationCode = `CA-${theLocation[0].abbv}`;
        // set full name
        let locationParts = theLocation[0].full.split(' ');
        let finalLocation = '';
        locationParts.forEach((part) => {
            finalLocation = finalLocation + (part[0].toUpperCase() + part.slice(1)) + ' ';
        });
        locationFullName = finalLocation;
        const response = await fetch(`${API_ENDPOINT_V2}/utilities/search?q=${theLocation[0].abbv.toUpperCase()}`);
        utilData = await response.json();
        utilData = utilData.utilities[Object.keys(utilData.utilities)[0]];
        console.log('uil data: ', utilData)
        let downTechs = new Set;
        let midTechs = new Set;
        // removing the concept of downstream / midstream switch on this page, just merge all techs into one list
        let allTechs = new Set;
        for (let i = 0; i < utilData.length; i++) {
            for (let j = 0; j < utilData[i].downstream_technologies.length; j++) {
                // console.log("checking: ",utilData[i].downstream_technologies[j].technology )
                if($availableTechnologies.length > 0) {
                    if ($availableTechnologies.includes(utilData[i].downstream_technologies[j].technology)) {
                        // downTechs.add(utilData[i].downstream_technologies[j].technology)
                        allTechs.add(utilData[i].downstream_technologies[j].technology)
                    }
                } else {
                    if (utilData[i].downstream_technologies[j].technology.toLowerCase().includes("custom")){
                        utilData[i].downstream_technologies[j].technology = utilData[i].downstream_technologies[j].technology.slice(0, -6)
                    }
                    // downTechs.add(utilData[i].downstream_technologies[j].technology)
                    allTechs.add(utilData[i].downstream_technologies[j].technology)
                }
            }
            for (let k = 0; k < utilData[i].midstream_technologies.length; k++) {
                if($availableTechnologies.length > 0) {
                    if ($availableTechnologies.includes(utilData[i].midstream_technologies[k].technology)) {
                        // midTechs.add(utilData[i].midstream_technologies[k].technology)
                        allTechs.add(utilData[i].midstream_technologies[k].technology)
                    }
                } else {
                    if (utilData[i].midstream_technologies[k].technology.toLowerCase().includes("custom")){
                        utilData[i].midstream_technologies[k].technology = utilData[i].midstream_technologies[k].technology.slice(0, -6)
                    }
                    // midTechs.add(utilData[i].midstream_technologies[k].technology)
                    allTechs.add(utilData[i].midstream_technologies[k].technology)
                }
            }

            utilData[i].downstream_technologies = Array.from(downTechs);
            utilData[i].midstream_technologies = Array.from(midTechs);
            utilData[i].all_technologies = Array.from(allTechs);
            downTechs.clear()
            midTechs.clear()

            if (utilData[i].all_technologies.length === 0){
                utilData[i].notechs = true;
            }
        }

        utilData.sort(compare);
        let orderedByInvestorOwned = [];
        const utilities = utilData;
        for(let i = 0, len = utilities.length; i < len; i ++) {
            const utility = utilities[i];
            if(utility && (utility.ownership === 'Investor Owned' || utility.ownership === 'State') && utility.program_status !== 'Closed') {
                if(utility.all_technologies.length !== 0 || utility.all_technologies.length !== 0) {
                    orderedByInvestorOwned.push(utility);
                }
            }
            if(orderedByInvestorOwned.length >= 6) {
                break;
            }
        }

        if(orderedByInvestorOwned.length === 0) {
            orderedByInvestorOwned = utilData.slice(0, 5)
        }

        visibleUtilities = orderedByInvestorOwned;
        allUtilities = utilData;
        filteredUtilities = utilData;
        loading = false;
    };

    function handleStreamChange(event) {
        selectedStream.set(event.detail.stream);
    }

    function filterUtilities(e) {
        const query = e.target.value;

        if(query === '') {
            filteredUtilities = allUtilities;
        }
        else {
            filteredUtilities = allUtilities.filter((utility) => {
                return utility.name.toLowerCase().includes(query.toLowerCase())
            });
        }
    }

</script>

<div class={`column-wrapper ${forceVerticalLayout ? 'forced-vertical' : ''}`}>
    <div class="ug-card map-card">
        <div class='ug-card-title'>
            <span>viewing utilities in</span>
            <h2>{locationFullName}</h2>
        </div>
        <div class='map-wrap'>
            <Map selectedLocation={locationCode} />
        </div>
    </div>
    <div class="ug-card">
        <div class='ug-card-title'>
            <span>try out these</span>
            <h2>Top Utilities</h2>
        </div>
        <!-- <div class='stream-toggle-wrap'>
            <StreamToggle on:stream-change={handleStreamChange} />
        </div> -->
        {#if loading}
        <Loader />
        {:else if utilData.length > 0}
        <div class='utility-cards-wrap'>
            {#each visibleUtilities as utility}
                <UtilityLocationCard {utility} technologies={utility.all_technologies} />
            {/each}
        </div>
        {/if}
        {#if visibleUtilities.length < allUtilities.length}
        <div class='see-all-utilities-wrap'>
            <span>Don't see what you're looking for?</span>
            <button on:click={() => allUtilitiesModalOpen = true}>See All Utilities for this {locationCode.includes('US') ? 'State' : 'Province'}</button>
        </div>
        {/if}
    </div>
    {#if allUtilitiesModalOpen}
    <Modal width='60%' on:request-close={() => allUtilitiesModalOpen = false}>
        <div class='ug-modal-body' slot='modal-content'>
            <h1>Utilities in {locationFullName}</h1>
            <input class='filter-input' type='text' placeholder="Find Utility" on:keyup={filterUtilities} />
            <div class='utility-cards-wrap'>
                {#each filteredUtilities as utility}
                    <UtilityLocationCard {utility} technologies={$selectedStream == 'downstream' ? utility.downstream_technologies : utility.midstream_technologies} />
                {/each}
            </div>
        </div>
    </Modal>
    {/if}
</div>

<style lang="scss">
    .column-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
        height: 70vh;
        margin-top: 1em;
        margin-bottom: 3em;

        .ug-card {
            height: 100%;
            border-radius: 10px;
            border: 1px solid rgb(228, 228, 228);
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
            background-color: white;
            padding: 10px 20px;
            overflow-y: scroll;
            position: relative;

            &.map-card {
                overflow: hidden;

                .map-wrap {
                    height: 80%;
                    overflow: hidden;
                    display: flex;
                    align-items: flex-start;
                    margin-top: 4em;
                    pointer-events: none;
                }
            }

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background: lightgray;
                border-radius: 10px;
                height: 30px;
            }

            .ug-card-title {
                padding-top: 0.75em;
                font-family: 'Source Sans Pro';

                span {
                    font-style: italic;
                    color: grey;
                    font-weight: 400;
                }

                h2 {
                    margin-top: 0;
                    margin-left: 1em;
                    font-size: 2rem;
                    font-weight: 400;
                }
            }

            .see-all-utilities-wrap {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 0.75em;
                font-family: 'IBM Plex Serif';
                width: 100%;

                span {
                    font-style: italic;
                    font-weight: 400;
                    display: block;
                    margin-bottom: 10px;
                }

                button {
                    background: transparent;
                    border: none;
                    color: rgb(0, 68, 68);
                    font-size: 1rem;
                    font-weight: 400;

                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }

        .utility-cards-wrap {
            max-height: 400px;
            height: 37vh;
            box-shadow: inset 0px 0px 14px 3px rgba(0,0,0,0.05);
            overflow-y: scroll;
            border-radius: 7px;
            padding-left: 0.65em;
            padding-right: 0.65em;
            margin-top: 1em;

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background: lightgray;
                border-radius: 10px;
                height: 30px;
            }
        }

        .ug-modal-body {
            padding-left: 1em;
            padding-right: 1em;

            h1 {
                font-family: 'Source Sans Pro';
                font-weight: 400;
                margin-top: 0.65em;
                margin-bottom: 0.5em;
            }

            input {
                width: 95%;
                border-radius: 25px;
                padding-left: 1em;
                padding-right: 1em;
                margin-left: auto;
                margin-right: auto;
                display: block;
                font-family: 'IBM Plex Serif';
                font-weight: 400;
                padding-top: 8px;
                padding-bottom: 8px;
                border: 1px solid #ddd;

                &:focus-visible {
                    outline: 1px solid #2c7873;
                }
            }
        }

        &.forced-vertical {
            grid-template-columns: 1fr;
            height: auto;

            .ug-card {
                &.map-card {
                    height: auto;
                    margin-bottom: 1em;
                    max-height: 500px;

                    .ug-card-title h2 {
                        margin-bottom: 0;
                    }

                    .map-wrap {
                        display: block;
                        height: 35vh;
                    }
                }

                .stream-toggle-wrap {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    @media screen and (max-width: 899px) {
        .column-wrapper {
            grid-template-columns: 1fr;
            height: auto;

            .ug-card {
                &.map-card {
                    height: auto;
                    margin-bottom: 1em;
                    max-height: 500px;

                    .ug-card-title h2 {
                        margin-bottom: 0;
                    }

                    .map-wrap {
                        display: block;
                        height: 35vh;
                    }
                }

                .stream-toggle-wrap {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        .column-wrapper {
            .ug-card {
                &.map-card {
                    .map-wrap {
                        height: 20vh;
                    }
                }
            }
        }
    }

</style>
