import { writable, get } from 'svelte/store';

// private constants
const NUM_PAGES = 4;
const STEPS = ['MAP', 'LOCATION', 'TECHNOLOGY', 'RESULT'];

// exports
export const page = writable('MAP'); // currently viewed page
export const history = writable(['MAP']); // record of nav history
export const selectedRegion = writable('');// user selected region/state
export const selectedUtility = writable({});// user selected utility eiauniq
export const selectedStream = writable('downstream'); // user selected stream
export const selectedTechnology = writable(''); // user selected technology
export const sessionTimestamp = writable(null);

// user defined values (defined in the UG widget portal)
export const widgetInstance = writable(null); // todo
export const widgetToken = writable(null);
export const availableTechnologies = writable([]);
export const categoriesByTechnology = writable([]);
export const sponsorshipSrc = writable('');
export const availableManufacturers = writable([]);
export const availableTerritories = writable([]);
export const mapPositionCache = writable({}); // todo comment

// ------------------

// resets store values
export const reset = () => {
    history.set(['MAP']);
    selectedRegion.set('');
    selectedUtility.set({});
    selectedStream.set('downstream');
    selectedTechnology.set('');
}

export const navigateForward = () => {
    const currentPageIndex = STEPS.indexOf(get(page));

    if (currentPageIndex !== -1 && currentPageIndex !== NUM_PAGES - 1) {
        const nextPage = STEPS[currentPageIndex + 1];
        let newHistory = get(history);
        newHistory.push(nextPage);

        page.set(nextPage);
        history.set(newHistory);
    }
}

export const navigateBackward = () => {
    const currentPageIndex = STEPS.indexOf(get(page));

    if (currentPageIndex !== -1 && currentPageIndex !== 0) {
        const previousPage = STEPS[currentPageIndex - 1];
        let newHistory = get(history);
        newHistory.pop();

        page.set(previousPage);
        history.set(newHistory);
    }
}

export const navigateToStep = (desiredPage) => {
    const currentPageIndex = STEPS.indexOf(get(page));
    const desiredPageIndex = STEPS.indexOf(desiredPage.toUpperCase());

    if (currentPageIndex !== -1 && desiredPageIndex !== -1) {
        const stepsUpToDesiredPage = STEPS.slice(0, desiredPageIndex + 1);

        history.set(stepsUpToDesiredPage);
        page.set(desiredPage.toUpperCase());
    }
}

export const getCachedSelections = () => {
    const selections = window.localStorage.getItem(`${get(widgetToken)}_ug_cached_selections_large`);
    if (!selections) {
        return [];
    }
    return JSON.parse(selections);
}
export const cacheSelection = () => {
    if (window) {
        const cachedSelections = window.localStorage.getItem(`${get(widgetToken)}_ug_cached_selections_large`);
        const newEntry = { utility: get(selectedUtility), stream: get(selectedStream), technology: get(selectedTechnology) };

        if (!cachedSelections) {
            window.localStorage.setItem(`${get(widgetToken)}_ug_cached_selections_large`, JSON.stringify([newEntry]));
        }
        else {
            const cache = JSON.parse(cachedSelections);

            // check for duplicate entries
            const duplicatedEntry = cache.find(cacheEntry => {
                const streamsMatch = cacheEntry.stream === newEntry.stream;
                const technologyMatch = cacheEntry.technology === newEntry.technology;
                const utilitiesMatch = cacheEntry.utility.eiauniq === newEntry.utility.eiauniq;
                if (streamsMatch && technologyMatch && utilitiesMatch) {
                    return cacheEntry;
                }
            });

            if (duplicatedEntry) {
                return;
            }

            if (cache.length < 3) {
                cache.unshift(newEntry);
                window.localStorage.setItem(`${get(widgetToken)}_ug_cached_selections_large`, JSON.stringify(cache));
            }
            else if (cache.length === 3) {
                cache.pop();
                cache.unshift(newEntry);
                window.localStorage.setItem(`${get(widgetToken)}_ug_cached_selections_large`, JSON.stringify(cache));
            }
        }
    }
}