<script>
    import { selectedStream, availableTechnologies, selectedUtility, widgetToken, sessionTimestamp } from '../store';
    import { onMount } from 'svelte';
    import TechnologyBoxes from '../components/TechnologyBoxes.svelte';
    import StreamToggle from '../components/StreamToggle.svelte';
    import UtilityMetaInformation from '../components/UtilityMetaInformation.svelte';
    import Modal from '../components/Modal.svelte';

    let technologies = [];
    const allTechnologies = [
        { icon: 'https://utility-genius.s3.amazonaws.com/icons/led.png', label: 'Lighting', technology: 'LED' },
        { icon: 'https://utility-genius.s3.amazonaws.com/icons/ctrl.png', label: 'Controls', technology: 'CTRL' },
        { icon: 'https://utility-genius.s3.amazonaws.com/icons/hvac.png', label: 'HVAC', technology: 'HVAC' },
        { icon: 'https://utility-genius.s3.amazonaws.com/icons/vfd.png', label: 'VFD', technology: 'VFD' },
        { icon: 'https://utility-genius.s3.amazonaws.com/icons/ev.png', label: 'EV', technology: 'EV' },
        { icon: 'https://utility-genius.s3.us-east-1.amazonaws.com/icons/other.png', label: '', technology: 'MORE' },

        // { icon: 'https://utility-genius.s3.amazonaws.com/icons/fan.png', label: 'Fans', technology: 'FAN' },
        // { icon: 'https://utility-genius.s3.amazonaws.com/icons/hort.png', label: 'Horticulture', technology: 'HORT' },
        // { icon: 'https://utility-genius.s3.amazonaws.com/icons/refrig.png', label: 'Refrigeration', technology: 'REFRIG' },
        // { icon: 'https://utility-genius.s3.amazonaws.com/icons/solar.png', label: 'Solar', technology: 'SOLAR' },
        // { icon: 'https://utility-genius.s3.amazonaws.com/icons/other.png', label: 'Other', technologies: 'OTHER' },
    ];
    let modalOpen = false;

    onMount(() => {
        filterAvailableTechnologies();

        if(window.gtag && !window.location.origin.includes('localhost')) {
            window.gtag('event', 'utility_viewed', {
                'event_label': $selectedUtility.name,
                'event_category': 'user_interaction',
                'non_interaction': true,
                'send_to': 'G-CYWFEE2MY3'
            });
        }

        try {
            fetch(`${UG_ENDPOINT}/api/widgets/setLastUsed`, {
                method: 'POST',
                body: JSON.stringify({
                    lastUsed: (new Date()).getTime() / 1000,
                    id: $widgetToken
                })
            })
            fetch(`${UG_ENDPOINT}/api/widgets/setJourney`, {
                method: 'POST',
                body: JSON.stringify({
                    timestamp: $sessionTimestamp,
                    id: $widgetToken,
                    step: 'utility'
                })
            })
        } catch (err) {
            // dont stop anything
            console.error('error tracking last used')
        }
    });

    async function filterAvailableTechnologies() {
        console.log('avail techs: ', $availableTechnologies)
        console.log("all techs: ", allTechnologies)

        technologies = allTechnologies;
        // if($availableTechnologies.length > 0) {
        //     if ($selectedStream == 'downstream') {
        //         technologies = allTechnologies.filter((technology) => {
        //             return $availableTechnologies.includes(technology.technology) && $selectedUtility.downstream_technologies.includes(technology.technology)
        //         });
        //     } else if ($selectedStream == 'midstream') {
        //         technologies = allTechnologies.filter((technology) => {
        //             return $availableTechnologies.includes(technology.technology) && $selectedUtility.midstream_technologies.includes(technology.technology)
        //         });
        //     }
        // }
        // else {
        //     if ($selectedStream == 'downstream') {
        //         technologies = allTechnologies.filter((technology) => {
        //             return $selectedUtility.downstream_technologies.includes(technology.technology)
        //         });
        //     } else if ($selectedStream == 'midstream') {
        //         technologies = allTechnologies.filter((technology) => {
        //             return $selectedUtility.midstream_technologies.includes(technology.technology)
        //         });
        //     }
        // }

        // fetch marketplace config
        const marketplaceResp = await fetch(`${UG_ENDPOINT}/api/business-marketplaces?eiauniq=${$selectedUtility.eiauniq}`);

        if(marketplaceResp.status === 200) {
            const { marketplace_config } = await marketplaceResp.json();

            // check to not double add the business marketplace tiles
            if(!technologies.find((tech) => tech.technology === 'BIZMRKT')) {
                // still keep a hard coded check for PG&E, until we have dynamic icons in place
                technologies = [ ...technologies, {
                    icon: $selectedUtility.eiauniq === '14328-CA' ? 'https://utility-genius.s3.amazonaws.com/icons/payperform.png' : "https://utility-genius.s3.amazonaws.com/icons/bizmrkt.png",
                    label: marketplace_config.tileTitle,
                    technology: 'BIZMRKT',
                    hidePill: !marketplace_config.showB2BPill,
                    link: marketplace_config.url
                }];
            }
        }
    }

    function handleStreamChange(event) {
        selectedStream.set(event.detail.stream);
    }
</script>

<div>
    <h1 class='title'>
        {$selectedUtility.name}
        <span class={`ug-badge ${$selectedUtility.program_status ? $selectedUtility.program_status.toLowerCase() : ''}`}>
            {$selectedUtility.program_status ? $selectedUtility.program_status.toUpperCase() : 'unknown'}
        </span>
    </h1>
    <div class='selection-wrap'>
        <div class='ug-card-title'>
            <span class='subtitle'>select your</span>
            <h2><span>Technology</span></h2>
        </div>
        <div class='divider'></div>
        <!-- <div class='toggle-wrap'>
            <StreamToggle on:stream-change={handleStreamChange} />
            <small class='what-does-this-mean' on:click={() => modalOpen = true}>What Does This Mean?</small>
        </div> -->
        <div class='technology-boxes-wrap'>
            <TechnologyBoxes {technologies} />
        </div>
    </div>
    <div class='utility-info-wrap'>
        <div class='ug-card-title'>
            <span class='subtitle'>check out this</span>
            <h2>Utility Information</h2>
        </div>
       <UtilityMetaInformation />
    </div>
    {#if modalOpen}
    <Modal width='90%' on:request-close={() => modalOpen = false}>
        <div class='ug-modal-body' slot='modal-content'>
            <h1>What Are <span>Instant</span> and <span>Prescriptive</span>?</h1>
            <p>
                An <b>instant</b> rebate is when a customer (you) receives the benefit directly from the distributor.
            </p>
            <p>
                A <b>prescriptive</b> rebate is when the customer or contractor must submit applications after installation of the product.
            </p>
        </div>
    </Modal>
    {/if}
</div>

<style lang='scss'>

    .title {
        display: flex;
        align-items: center;
        font-family: 'Source Sans Pro';
        font-weight: 400;

        .ug-badge {
            font-size: 0.85rem;
            margin-left: 10px;
            font-weight: 400;
            padding: 4px 10px;
            border-radius: 25px;
            background-color: rgb(219, 167, 39);
            color: white;
            font-family: 'IBM Plex Serif';
            font-weight: 400;
            line-height: 1;

            &.open {
                background-color: rgb(44, 120, 115);
                color: white;
            }

            &.closed {
                background-color: rgb(130, 9, 25);
                color: white;
            }
        }
    }

    .selection-wrap {
        border: 1px solid #ddd;
        background-color: white;
        border-radius: 10px;
        padding: 1em;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);

        .ug-card-title {
            font-family: 'Source Sans Pro';

            .subtitle {
                font-style: italic;
                color: grey;
                font-weight: 400;
            }

            h2 {
                margin-top: 0;
                margin-left: 1em;
                font-size: 1.75rem;
                font-weight: 400;

                span {
                    font-weight: bold;
                }
            }
        }

        .divider {
            width: 60%;
            height: 1px;
            background-color: lightgray;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2em;
        }

        .toggle-wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 2em;

            .what-does-this-mean {
                display: block;
                margin-top: 0.5em;
                cursor: pointer;
                color: rgb(0, 68, 68);
                width: 20%;
                text-align: center;
                font-family: 'IBM Plex Serif';

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .utility-info-wrap {
        border: 1px solid #ddd;
        background-color: white;
        border-radius: 10px;
        padding: 1.25em;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        margin-top: 1em;

        .ug-card-title {
            font-family: 'Source Sans Pro';

            .subtitle {
                font-style: italic;
                color: grey;
                font-weight: 400;
            }

            h2 {
                margin-top: 0;
                margin-left: 1em;
                font-size: 1.75rem;
                font-weight: 600;
            }
        }
    }

    .ug-modal-body {
        padding: 1em;
        font-family: 'IBM Plex Serif';

        h1 {
            margin-bottom: 1.25em;
            font-weight: 400;
            font-family: 'Source Sans Pro';

            span {
                font-weight: 600;
            }
        }
    }

    .technology-boxes-wrap {
        margin-top: 15px;
    }

    @media screen and (max-width: 768px) {

        .selection-wrap {
            .card-title {
                h2 {
                    margin-bottom: 10px;
                }
            }
            .divider {
                margin-bottom: 10px;
            }
            .toggle-wrap {
                .what-does-this-mean {
                    width: 100%;
                }
            }
        }
    }

</style>
