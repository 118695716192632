const data = [
    [
      'State',
      ''
    ],
    [
      'CA-AB',
      {
        v: 5,
        f: ''
      }
    ],
    [
      'CA-BC',
      {
        v: 6,
        f: ''
      }
    ],
    [
      'CA-MB',
      {
        v: 2,
        f: ''
      }
    ],
    [
      'CA-NL',
      {
        v: 3,
        f: ''
      }
    ],
    [
      'CA-ON',
      {
        v: 4,
        f: ''
      }
    ],
    [
      'CA-PE',
      {
        v: 1,
        f: ''
      }
    ],
    [
      'CA-QC',
      {
        v: 8,
        f: ''
      }
    ],
    [
      'CA-NS',
      {
        v: 6,
        f: ''
      }
    ],
    [
      'CA-NB',
      {
        v: 9,
        f: ''
      }
    ],
    [
      'CA-NT',
      {
        v: 9,
        f: ''
      }
    ],
    [
      'CA-SK',
      {
        v: 9,
        f: ''
      }
    ]
  ];

  export default data;