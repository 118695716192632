<script>
    export let utility; // full utility object
    export let technologies; // list of available technologies given the stream
    import { onMount } from 'svelte';
    import { selectedUtility, selectedTechnology, navigateForward, navigateToStep } from '../store';
    import icons from '../../utils/icons';

    const { ALLICONS_DARK } = icons();
    let orderedTechnologies = [];

    onMount(() => {

        // the icons need to be ordered in the order that matches everything else UG
        orderedTechnologies = [
            ...(technologies.includes('LED') ? [ 'LED' ] : []),
            ...(technologies.includes('CTRL') ? [ 'CTRL' ] : []),
            ...(technologies.includes('HVAC') ? [ 'HVAC' ] : []),
            ...(technologies.includes('VFD') ? [ 'VFD' ] : []),
            ...(technologies.includes('EV') ? [ 'EV' ] : []),
            // ...(technologies.includes('FAN') ? [ 'FAN' ] : []),
            // ...(technologies.includes('HORT') ? [ 'HORT' ] : []),
            // ...(technologies.includes('REFRIG') ? [ 'REFRIG' ] : []),
            // ...(technologies.includes('SOLAR') ? [ 'SOLAR' ] : []),
            // ...(technologies.includes('OTHER') ? [ 'OTHER' ] : []),
        ]
    });

    function selectUtility(util) {
        selectedUtility.set(util)
        navigateForward()
    }
    function selectUtilityAndTechnology(util, tech) {
        selectedUtility.set(util)
        selectedTechnology.set(tech)
        navigateToStep('RESULT')
    }
</script>

<div class="util-card" style="{technologies.length > 3 && 'flex-direction: column;'}">
    <div class="ug-border-left"></div>
    {#if utility.notechs}
        <span class="util-name">{utility.name}</span>
    {:else}
        <span on:click={() => selectUtility(utility)} class="{technologies.length > 3 ? 'util-name-aux' : 'util-name'} ug-clickable">{utility.name}</span>
    {/if}
    <div class="icons-box">
        <div class="icons">
        {#if orderedTechnologies.length > 0}
            {#each orderedTechnologies as technology}
                <span class="icon" on:click={() => selectUtilityAndTechnology(utility, technology)}>
                    <img src={ALLICONS_DARK[technology]} alt="Technology" />
                    <span class='icon-tech-label'>{technology.toUpperCase()}</span>
                </span>
            {/each}
        {:else if utility.notechs}
            <span class='no-rebate-available-message'>
                <span>
                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style="color: rgb(130, 9, 25); margin-left: 5px;"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                </span>
                No Rebate Available
            </span>
        {/if}
        </div>
    </div>
</div>

<style lang="scss">
    .util-card {
        display: flex;
        position: relative;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        width: auto;
        border-radius: 10px;
        border: 1px solid rgb(228, 228, 228);
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        margin: 16px 0;
        min-height: 40px;
        // flex-direction: column;
        // text-align: left;


        .ug-border-left {
            width: 7px;
            background-color: rgb(208, 242, 225);
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
        }

        .util-name {
            padding-left: 1em;
            width: 46%;
            font-family: 'IBM Plex Serif';
        }

        .util-name-aux {
            padding: .5em 0 0 1.5em;
            width: 100%;
            font-family: 'IBM Plex Serif';
            font-weight: 400;
        }


        .ug-clickable:hover {
            cursor: pointer;
            text-decoration:underline;
        }

        .icons-box {
            height: 100%;
            display: flex;
            padding-bottom: .25em;

            .icons {
                margin: auto 10px auto auto;
                display: flex;

                .icon {
                    position: relative;
                    margin-right: 8px;

                    &:hover {
                        cursor: pointer;
                    }

                    img {
                        width: 30px;
                        height: 30px;
                        object-fit: contain;
                    }

                    .icon-tech-label {
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        font-size: 10px;
                        background: #c7c7c7;
                        font-weight: 600;
                        border-radius: 10px;
                        height: 10px;
                        padding: 1px 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transform: translateX(-50%);
                        width: 34px;
                        font-family: 'IBM Plex Serif';
                    }
                }
            }

            .no-rebate-available-message {
                color: rgb(130, 9, 25);
                font-style: italic;
                font-family: 'IBM Plex Serif';
            }
        }
    }

    @media screen and (max-width: 768px) {
        .util-card {
            flex-direction: column;
            align-items: flex-start;
            padding-top: 10px;
            padding-bottom: 10px;

            .util-name {
                width: 90%;
                margin-bottom: 10px;
                text-align: center;
            }

            .icons-box {
                padding-left: 1em;
                align-self: center;

                .icon {
                    margin-right: 12px;
                }
            }
        }
    }
</style>