const territoriesLookup = Object.freeze({
    // 'USA': 'usa',
    // 'CANADA': 'canada',
    "US-KY": 'kentucky',
    "US-OH": 'ohio',
    "US-WV": 'west virginia',
    "US-NJ": 'new jersey',
    "US-MD": 'maryland',
    "US-IL": 'illinois',
    "US-NC": 'north carolina',
    "US-VA": 'virginia',
    "US-IN": 'indiana',
    "US-PA": 'pennsylvania',
    "US-TX": 'texas',
    "US-AZ": 'arizona',
    "US-MS": 'mississippi',
    "US-LA": 'louisiana',
    "US-MN": 'minnesota',
    "US-WI": 'wisconsin',
    "US-NY": 'new york',
    "US-GA": 'georgia',
    "US-IA": 'iowa',
    "US-SC": 'south carolina',
    "US-NV": 'nevada',
    "US-AK": 'alaska',
    "US-AL": 'alabama',
    "US-FL": 'florida',
    "US-CA": 'california',
    "US-MO": 'missouri',
    "US-ID": 'idaho',
    "US-WA": 'washington',
    "US-KS": 'kansas',
    "US-OK": 'oklahoma',
    "US-MI": 'michigan',
    "US-NE": 'nebraska',
    "US-TN": 'tennessee',
    "US-AR": 'arkansas',
    "US-SD": 'south dakota',
    "US-MA": 'massachusetts',
    "US-NH": 'new hampshire',
    "US-OR": 'oregon',
    "US-CO": 'colorado',
    "US-NM": 'new mexico',
    "US-ME": 'maine',
    "US-VT": 'vermont',
    "US-MT": 'montana',
    "US-WY": 'wyoming',
    "US-UT": 'utah',
    "US-RI": 'rhode island',
    "US-CT": 'connecticut',
    "US-ND": 'north dakota',
    "US-DE": 'delaware',
    "US-HI": 'hawaii',
    "US-DC": 'washington dc', // Washington, D.C.
    "US-PR": 'puerto rico',
    "US-VI": 'virgin islands',
    "US-GU": 'guam',
    "US-AS": 'american samoa',
    "CA-AB": 'alberta',
    "CA-BC": 'british columbia',
    "CA-MB": 'manitoba',
    "CA-NB": 'new brunswick',
    "CA-NL": 'newfoundland and labrador',
    "CA-NS": 'nova scotia',
    "CA-NT": 'northwest territories',
    "CA-ON": 'ontario',
    "CA-PE": 'prince edward island',
    "CA-QC": 'quebec',
    "CA-SK": 'saskatchewan',
});

const territoryKeys = Object.keys(territoriesLookup);

export function getStateAbbv(search) {

    // performance bail out
    if(search.length < 3) {
        return [];
    }

    let matchedTerritories = [];

    for(let i = 0, totalKeys = territoryKeys.length; i < totalKeys; i ++) {

        const territory = territoriesLookup[territoryKeys[i]];

        if(territory == search.toLowerCase()) {
            matchedTerritories.push({ abbv: territoryKeys[i].split('-')[1], full: territory })
        }
    }
    return matchedTerritories;
}


export function getIsStateAbbvInUSA(abbreviation) {

    let includesUSA = false;

    for(let i = 0, totalKeys = territoryKeys.length; i < totalKeys; i ++) {

        const territoryAbbv = territoryKeys[i];

        if(territoryAbbv.toLowerCase().includes(abbreviation.toLowerCase())) {
            if(territoryAbbv.includes('US')) {
                includesUSA = true;
            }
            break;
        }
    }

    return includesUSA;
}

// function to test if an arbitrary string happens to be a location code or 'almost' a location code
// e.g. NY would still return true because of US-NY
export function getIsAStateAbbv(query) {

    if(query.includes('US-') || query.includes('CA-')) {
        if(territoriesLookup[query]) {
            return true;
        }
    }
    else {
        const isUSATerritory = territoriesLookup[`US-${query.toUpperCase()}`];

        if(isUSATerritory) {
            return true;
        }

        const isCanadaTerritory = territoriesLookup[`CA-${query.toUpperCase()}`];

        if(isCanadaTerritory) {
            return true;
        }
    }

    return false;
}

export function lookup(locationCode) {

    if(territoriesLookup.hasOwnProperty(locationCode)) {
        const locationName = territoriesLookup[locationCode];

        return locationName.replaceAll(' ', '-');
    }
    else {
        return new Error('Location not found.')
    }
}

