<script>
    import { selectedUtility } from '../store';
</script>
<div class='meta-info-wrap'>
    <ul>
        <li>
            <p><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                    height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                        d="M256 48c-79.5 0-144 61.39-144 137 0 87 96 224.87 131.25 272.49a15.77 15.77 0 0025.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137z">
                    </path>
                    <circle cx="256" cy="192" r="48" fill="none" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="32"></circle>
                </svg><b style="margin-left: 5px;"> Location:</b> {$selectedUtility.state}</p>
        </li>
        <li>
            <p><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                    height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                        d="M160 368L32 256l128-112m192 224l128-112-128-112"></path>
                </svg><b style="margin-left: 5px;">EIA Code:</b> {$selectedUtility.eiauniq}</p>
        </li>
        <li>
            <p><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                    height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <rect width="448" height="320" x="32" y="128" fill="none" stroke-linejoin="round"
                        stroke-width="32" rx="48" ry="48"></rect>
                    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                        d="M144 128V96a32 32 0 0132-32h160a32 32 0 0132 32v32m112 112H32m288 0v24a8 8 0 01-8 8H200a8 8 0 01-8-8v-24">
                    </path>
                </svg><b style="margin-left: 5px;">Ownership:</b> {$selectedUtility.ownership}</p>
        </li>
        <li>
            <p><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                    height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" stroke-miterlimit="10" stroke-width="32"
                        d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"></path>
                    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                        d="M250.26 166.05L256 288l5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 6z">
                    </path>
                    <path d="M256 367.91a20 20 0 1120-20 20 20 0 01-20 20z"></path>
                </svg><b style="margin-left: 5px;">Rebate Program:</b> {$selectedUtility.program_name}
            </p>
        </li>
        <li>
            <p><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                    height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M256 464c-114.69 0-208-93.31-208-208S141.31 48 256 48s208 93.31 208 208-93.31 208-208 208zm0-384c-97 0-176 79-176 176s79 176 176 176 176-78.95 176-176S353.05 80 256 80z">
                    </path>
                    <path
                        d="M323.67 292c-17.4 0-34.21-7.72-47.34-21.73a83.76 83.76 0 01-22-51.32c-1.47-20.7 4.88-39.75 17.88-53.62S303.38 144 323.67 144c20.14 0 38.37 7.62 51.33 21.46s19.47 33 18 53.51a84 84 0 01-22 51.3C357.86 284.28 341.06 292 323.67 292zm55.81-74zm-215.66 77.36c-29.76 0-55.93-27.51-58.33-61.33-1.23-17.32 4.15-33.33 15.17-45.08s26.22-18 43.15-18 32.12 6.44 43.07 18.14 16.5 27.82 15.25 45c-2.44 33.77-28.6 61.27-58.31 61.27zm256.55 59.92c-1.59-4.7-5.46-9.71-13.22-14.46-23.46-14.33-52.32-21.91-83.48-21.91-30.57 0-60.23 7.9-83.53 22.25-26.25 16.17-43.89 39.75-51 68.18-1.68 6.69-4.13 19.14-1.51 26.11a192.18 192.18 0 00232.75-80.17zm-256.74 46.09c7.07-28.21 22.12-51.73 45.47-70.75a8 8 0 00-2.59-13.77c-12-3.83-25.7-5.88-42.69-5.88-23.82 0-49.11 6.45-68.14 18.17-5.4 3.33-10.7 4.61-14.78 5.75a192.84 192.84 0 0077.78 86.64l1.79-.14a102.82 102.82 0 013.16-20.02z">
                    </path>
                </svg><b style="margin-left: 5px;">C&amp;I Customers:</b> {$selectedUtility.nonrescustomers?.toLocaleString() || '--'}</p>
        </li>
    </ul>
    <ul>
        <li>
            <p><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                    height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                        d="M344 280l88-88m-200 24l64 64M80 320l104-104"></path>
                    <circle cx="456" cy="168" r="24" fill="none" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="32"></circle>
                    <circle cx="320" cy="304" r="24" fill="none" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="32"></circle>
                    <circle cx="208" cy="192" r="24" fill="none" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="32"></circle>
                    <circle cx="56" cy="344" r="24" fill="none" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="32"></circle>
                </svg><b style="margin-left: 5px;">C+I kWh Rate:</b>
                {$selectedUtility.average_electric_rate === null ? '--' : $selectedUtility.average_electric_rate.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                })}</p>
        </li>
        <li>
            <p><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                    height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"
                        d="M216.08 192v143.85a40.08 40.08 0 0080.15 0l.13-188.55a67.94 67.94 0 10-135.87 0v189.82a95.51 95.51 0 10191 0V159.74">
                    </path>
                </svg><b style="margin-left: 5px;">Main Utility Link:</b> <a
                    href={$selectedUtility.utility_link}
                    target="_blank" rel="noreferrer"
                    style="color: rgb(0, 68, 68); text-decoration: underline;">Visit Here</a></p>
        </li>
        <li>
            <p><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                    height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" stroke-miterlimit="10" stroke-width="32"
                        d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"></path>
                    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                        d="M352 176L217.6 336 160 272"></path>
                </svg><b style="margin-left: 5px;">Pre-Approval?:</b> {$selectedUtility.preapproval}</p>
        </li>
        <li>
            <p><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                    height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" stroke-linejoin="round" stroke-width="32"
                        d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z">
                    </path>
                    <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                        d="M256 56v120a32 32 0 0032 32h120m-232 80h160m-160 80h160"></path>
                </svg><b style="margin-left: 5px;">Pre-Approval Timing:</b> {$selectedUtility.preapproval_milestone}</p>
        </li>
    </ul>
</div>

<style lang='scss'>

    .meta-info-wrap {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        font-family: 'IBM Plex Serif';

        ul {
            width: 49%;
            list-style: none;
            margin-top: 0;

            svg {
                color: #6fb98f;
            }
        }

        p {
            font-weight: 400;
        }
    }

    @media screen and (max-width: 768px) {

        .meta-info-wrap {
            flex-direction: column;
            align-items: center;

            ul {
                width: 100%;
                margin-bottom: 0;
            }
        }
    }
</style>