<script>
    import { createEventDispatcher, onMount } from 'svelte';
    import { tweened } from 'svelte/motion';
    import { cubicInOut } from 'svelte/easing';
    import { widgetInstance } from '../store';

    const dispatch = createEventDispatcher();
    let activeOption;

    onMount(() => {
        activeOption = $widgetInstance.countryPreference || 'usa';
        console.log('active: ', activeOption)
    });

    const activeOptionBackdropPosition = tweened(0, {
        duration: 100,
        easing: cubicInOut
    });

    function handleRegionChange(region) {
        activeOption = region;
        // if the user has defined a country preference
        if($widgetInstance.countryPreference) {
            const countryPreferenceMatchesRegionSelection =
                ($widgetInstance.countryPreference === 'usa' && region === 'usa') ||
                ($widgetInstance.countryPreference === 'canada' && region === 'canada');

            activeOptionBackdropPosition.set(countryPreferenceMatchesRegionSelection ? 0 : 85);
        }
        // no country preference found on this widget instance
        else {
            activeOptionBackdropPosition.set(region === 'usa' ? 0 : 85);
        }
        dispatch('region-change', {
            'region': activeOption
        })
    }

</script>

<div>
    <div class='region-toggle-wrap'>
        <div class='ug-switch'>
            {#if $widgetInstance.countryPreference && $widgetInstance.countryPreference === 'usa' }
                <span class='ug-switch-option' class:active={activeOption === 'usa'} on:click={() => handleRegionChange('usa')}>USA</span>
                <span class='ug-switch-option' class:active={activeOption === 'canada'} on:click={() => handleRegionChange('canada')}>Canada</span>
            {:else}
                <span class='ug-switch-option' class:active={activeOption === 'canada'} on:click={() => handleRegionChange('canada')}>Canada</span>
                <span class='ug-switch-option' class:active={activeOption === 'usa'} on:click={() => handleRegionChange('usa')}>USA</span>
            {/if}
            <div class='backdrop' style="left: {$activeOptionBackdropPosition}px;"></div>
        </div>
    </div>
</div>

<style lang='scss'>

    .region-toggle-wrap {
        border: 1px solid #a7a5a5;
        border-radius: 25px;
        padding: 3px 10px;
        background-color: #f9f7f7;
        word-wrap: normal;

        .ug-switch {
            display: flex;
            align-items: center;
            position: relative;

            .backdrop {
                position: absolute;
                width: 85px;
                border-radius: 25px;
                top: 0;
                bottom: 0;
                background-color: rgb(44, 120, 115);
                z-index: 1;
            }

            .ug-switch-option {
                background-color: transparent;
                padding: 3px 15px;
                border-radius: 25px;
                cursor: pointer;
                width: 55px;
                text-align: center;
                z-index: 2;
                font-family: 'IBM Plex Serif';
                box-sizing: content-box;
                font-weight: 400;
                font-size: 1rem;

                &.active {
                    color: white;
                }
            }
        }
    }

</style>