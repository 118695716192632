<script>
    import { onMount } from 'svelte';
    import {
    widgetInstance,
    getCachedSelections,
    selectedUtility,
    selectedStream,
    selectedTechnology,
    navigateToStep,
    availableTerritories,
    reset } from '../store';

    import Map from '../components/Map.svelte';
    import RegionToggle from '../components/RegionToggle.svelte';
    import SearchBar from '../components/SearchBar.svelte';
    import StateDropdown from '../components/StateDropdown/StateDropdown.svelte';

    let activeRegion;
    let hideDropdown = false;
    let hideRegionToggle = false;
    let mapCalculated = false;
    let cachedSelections = [];

    onMount(() => {
        activeRegion = $widgetInstance.countryPreference || 'usa';
        reset();
        handleGetCachedSelections();
    });

    // only show recent searches that point to utilities in the defined set of available territories
    // (if applicable)
    function handleGetCachedSelections() {

        if($availableTerritories.length > 0) {
            const allSelections = getCachedSelections();
            console.log('all: ', allSelections)
            allSelections.forEach((selection) => {
                const selectionLocation = `${selection.utility.country}-${selection.utility.state}`;
                const selectionInRegion = $availableTerritories.find((territory) => {
                    return territory.value === selectionLocation;
                });
                if(selectionInRegion) {
                    cachedSelections.push(selection)
                }
            })
        }
        else {
            cachedSelections = getCachedSelections();
        }
    }

    function handleRegionChange(event) {
        activeRegion = event.detail.region;
    }

    function handleRecentSelectionClick(utility, stream, technology) {
        selectedUtility.set(utility);
        selectedStream.set(stream);
        selectedTechnology.set(technology);
        navigateToStep('RESULT');
    }

    function handleMapWidthCalculated(event) {
        hideDropdown = event.detail.shouldHideDropdown;
        hideRegionToggle = event.detail.shouldHideRegionToggle;
        mapCalculated = true;
    }

</script>


<div class='map-wrap'>
    <div class='title-search-wrap'>
        <h1 class='ug-title'>Find A Program</h1>
       <div class='search-bar-wrap'>
            <SearchBar />
       </div>
    </div>
    <div class='map-box' class:extra-bottom={cachedSelections.length > 0}>
        <div class='map-header' class:align-right={hideRegionToggle}>
            {#if mapCalculated}
                {#if !hideRegionToggle}
                    <RegionToggle on:region-change={handleRegionChange} />
                {/if}
                <!-- the 'hidden-dropdown' class name is a misnomer  -->
                <!-- the state dropdown will display: block; on mobile screens regardless of the 'hideDropdown' variable value  -->
                <div class='state-dropdown-wrap' class:hidden-dropdown={hideDropdown}>
                    <StateDropdown />
                </div>
            {/if}
        </div>
        <Map {activeRegion} on:calculated-width={handleMapWidthCalculated} />
        {#if cachedSelections.length > 0}
            <div class='recent-searches'>
                <span class='recent-searches-label'>Recent Searches:</span>
                <div class='searches'>
                    {#each cachedSelections as selection}
                        <div class='recent-selection-item'>
                            <button
                                class='recent-search-button'
                                on:click={() => handleRecentSelectionClick(selection.utility, selection.stream, selection.technology)}
                            >
                                <span>{selection.utility.name}</span>
                            </button>
                        </div>
                    {/each}
                </div>
            </div>
        {/if}
    </div>
</div>

<style lang='scss'>

    .map-wrap {
        max-width: 1080px;

        .title-search-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .ug-title {
                font-family: 'Source Sans Pro';
                font-size: 2rem;
                font-weight: 600;
                padding: 0;
                margin: 0;
            }

            .search-bar-wrap {
                width: 30%;
                padding-right: 20px;
            }
        }

        .map-box {
            background-color: white;
            border-radius: 25px;
            padding: 20px 20px 10px 20px;
            border: 1px solid #f1f0f0;
            box-shadow: 0 20px 25px -5px rgb(0, 0, 0, 0.1);
            // max-height: 525px;
            position: relative;
            margin-top: 15px;

            .map-header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &.align-right {
                    justify-content: flex-end;
                }

                .state-dropdown-wrap {
                    width: 30%;
                }

                .hidden-region-toggle, .hidden-dropdown {
                    display: none;
                }
            }
        }

        .recent-searches {
            display: flex;
            align-items: center;
            font-family: 'IBM Plex Serif';

            .recent-searches-label {
                color: #004444;
                font-size: 18px;
                font-weight: 400;
            }

            .searches {
                display: flex;
                align-items: center;

                .recent-search-button {
                    margin-bottom: 0;
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
                    background-color: #2C7873;
                    background-image: none;
                    color: white;
                    border: none;
                    border-radius: 7px;
                    margin-left: 10px;
                    cursor: pointer;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    font-family: 'IBM Plex Serif';

                    span {
                        color: white;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1080px) {
        .map-wrap {
            .map-box {
                &.extra-bottom {
                    padding-bottom: 3em;
                }
            }
        }
    }

    @media screen and (max-width: 992px){
        .recent-searches {
            flex-direction: column;
        }
    }

    @media screen and (max-width: 768px) {

        .map-wrap {

            .title-search-wrap {
                flex-direction: column;

                h1 {
                    margin-bottom: 8px;
                }

                .search-bar-wrap {
                    margin-bottom: 15px;
                    width: 80%;
                    padding: 0;
                }
            }

            .map-box {
                max-height: unset;

                .map-header {
                    flex-direction: column;

                    .state-dropdown-wrap {
                        width: 60%;
                        margin-top: 15px;
                    }

                    .hidden-region-toggle, .hidden-dropdown {
                        display: block;
                    }
                }

                &.extra-bottom {
                    padding-bottom: 1em;
                }
            }

            // .recent-searches {
            //     flex-direction: column;
            // }
        }
    }

    @media screen and (max-width: 568px) {

        .map-wrap {
            .map-box {
                .map-header {
                    .state-dropdown-wrap {
                        width: 90%;
                    }
                }
            }
            .recent-searches {
                .searches {
                    flex-direction: column;

                    .recent-selection-item {
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
</style>