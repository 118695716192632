const MIXED_RATES = [ 'kWh+kW', 'kWkWhMax', 'kWkWhMin' ];
const lookup = Object.freeze({
    'Fan': 'per fan',
    'Fixture': 'per fixture',
    'Foot Diameter': 'per foot diameter',
    'HP': 'per horsepower',
    'Inch Diameter': 'per inch diameter',
    'Lamp': 'per lamp',
    'Linear Foot': 'per linear foot',
    'Sensor': 'per sensor',
    'Square Foot': 'per square foot',
    'Ton': 'per ton',
    'Unit': 'per unit',
    'kBtu/h': 'per kBtu/h',
    'Fixed': 'per unit',
    'kWh': 'per kWh saved',
    'kW': 'per peak kW reduced',
    'Watts': 'per Watt reduced',
    'WattsPost': 'per Watt of proposed fixture',
    // NE: '',
    'kWraw': 'per kW reduced',
    'WattsCtrl': 'per Watt controlled',
    'Lumen': 'per Lumen',
    'KiloLumen': 'per KiloLumen',
    // Lumen58: '',
    // Lumen80: '',
    'MFixed': 'per unit',
    'MKiloLumen': 'per KiloLumen',
    'MLumen': 'per Lumen',
    'MHP': 'per Horsepwer',
    'MWattsPost': 'per Watt of proposed fixture',
});


function _lookupLabel(unit) {

    if(Object.prototype.hasOwnProperty.call(lookup, unit)) {
        return lookup[unit];
    }
    else {
        return '';
    }
}

function _formatUSD(number) {
    if(!number) {
        return '$0.00';
    }

    const currency = (Number(number)).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    return currency || '$0.00';
}

/*
* returns: range: string ( rebate range w/ units as a string )
*/
export default function formatRebateRate(category) {

    let range = '';

   if(MIXED_RATES.includes(category.inctype)) {

        if(category.inctype === 'kWh+kW') {

            let minRate;
            let maxRate;
            // if a mixed rate and the API does not split the rates out (it does in the production version of the UG API, but not the dev one,
            // safeguard just in-case)
            if(category.min_rate === category.max_rate) {
                const rates = category.min_rate.toString().split('.');
                const kwSaved = parseFloat(rates[0]);
                const kWhSaved = parseFloat(`.${rates[1]}`);

                minRate = _formatUSD(kwSaved);
                maxRate = _formatUSD(kWhSaved);
            }
            else {
                minRate = _formatUSD(category.min_rate);
                maxRate = _formatUSD(category.max_rate);
            }

            range = `${minRate}/kW reduced + ${maxRate}/kWh saved`;
        }
        else {
            const rates = category.min_rate.toString().split('.');
            const kwSaved = parseFloat(rates[0]);
            const kWhSaved = parseFloat(`.${rates[1]}`);
            console.log('rates', rates);

            range = `The ${category.inctype === 'kWkWhMax' ? 'greater':'lesser'} of ${_formatUSD(kwSaved)}/kW and ${_formatUSD(kWhSaved)}/kWh`;
        }
   }
   // chillers
   else if(category.inctype === 'Formula') {
        const min_rate = _formatUSD(parseFloat(category.min_rate));
        const max_rate = _formatUSD(parseFloat(category.max_rate));
        const min_rate_label = category.min_rate.replace(/[0-9]/g, '').replace('.', '')
        const max_rate_label = category.max_rate.replace(/[0-9]/g, '').replace('.', '')

        if (min_rate === max_rate) {
            range = `${min_rate}${min_rate_label}`;
        }
        else {
            range = `${min_rate}${min_rate_label} - ${max_rate}${max_rate_label}`;
        }
   }
   else if(category.min_rate === category.max_rate) {
        const rate = _formatUSD(category.min_rate);
        let units = '';
        if(category.inctype === 'Fixed' || category.inctype === 'MFixed') {
            units = _lookupLabel(category.unit);
        }
        else {
            units = _lookupLabel(category.inctype);
        }

        range = `${rate} ${units}`;
    }
   else {
        const rate = `${_formatUSD(category.min_rate)} - ${_formatUSD(category.max_rate)}`;
        let units = '';
        if(category.inctype === 'Fixed' || category.inctype === 'MFixed') {
            units = _lookupLabel(category.unit);
        }
        else {
            units = _lookupLabel(category.inctype);
        }

        range = `${rate} ${units}`;
   }

   return range;
}