<script>
    export let width = '50%';
    import { createEventDispatcher } from 'svelte';
    import { fly } from 'svelte/transition';

    const dispatch = createEventDispatcher();

    // the parent component will be responsible for closing the modal.
    // this component can request to run the parent's close logic
    function handleRequestClose() {
        dispatch('request-close');
    }

</script>

<div class='ug-modal-backdrop' on:click={handleRequestClose}></div>
<div class='ug-widget-modal' style={`width: ${width};`} in:fly="{{ duration: 350, y: 100, opacity: 0.5 }}" out:fly="{{ duration: 150, y: 100 }}">
    <span class='ug-modal-close' on:click={handleRequestClose}>
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>
    </span>
    <slot name='modal-content'></slot>
</div>

<style lang='scss'>

    .ug-modal-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.15);
        z-index: 99;
    }

    .ug-widget-modal {
        background: white;
        height: auto;
        max-height: 575px;
        min-height: 250px;
        position: absolute;
        top: 3em;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 10px;
        z-index: 100;
        overflow: scroll;
        display: block !important; 

        .ug-modal-close {
            position: absolute;
            right: 10px;
            top: 5px;
            font-size: 22px;
            cursor: pointer;
            z-index: 100;
        }


        &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background: lightgray;
                border-radius: 10px;
                height: 30px;
            }
    }

    // modal component will overwrite the prop and make itself full width on smaller screen sizes
    // i can't think of a scenario where a modal will not want to be max-width on phones
    @media screen and (max-width: 889px) {
        .ug-widget-modal {
            width: 95% !important;
        }
    }
</style>