<script>
    import { createEventDispatcher } from 'svelte';
    import { tweened } from 'svelte/motion';
    import { cubicInOut } from 'svelte/easing';

    const dispatch = createEventDispatcher();
    let activeOption = 'downstream';

    const activeOptionBackdropPosition = tweened(0, {
        duration: 100,
        easing: cubicInOut
    });

    function handleStreamChange(stream) {
        activeOption = stream;
        activeOptionBackdropPosition.set(stream === 'downstream' ? 0 : 117);
        dispatch('stream-change', {
            'stream': activeOption
        })
    }

</script>

<div>
    <div class='stream-toggle-wrap'>
        <div class='ug-switch'>
            <span class='ug-switch-option' class:active={activeOption === 'downstream'} on:click={() => handleStreamChange('downstream')}>Prescriptive</span>
            <span style='padding-left: 15px;' class='ug-switch-option' class:active={activeOption === 'midstream'} on:click={() => handleStreamChange('midstream')}>Instant</span>
            <div class='backdrop' style="left: {$activeOptionBackdropPosition}px;"></div>
        </div>
    </div>
</div>

<style lang='scss'>

    .stream-toggle-wrap {
        border: 1px solid #a7a5a5;
        border-radius: 25px;
        padding: 3px 10px;
        background-color: #f9f7f7;
        width: 230px;
        box-sizing: content-box;

        .ug-switch {
            display: flex;
            align-items: center;
            position: relative;

            .backdrop {
                position: absolute;
                width: 117px;
                border-radius: 25px;
                top: 0;
                bottom: 0;
                background-color: rgb(44, 120, 115);;
                z-index: 1;
            }

            .ug-switch-option {
                background-color: transparent;
                padding: 3px 15px 3px 10px;
                border-radius: 25px;
                cursor: pointer;
                width: 100px;
                text-align: center;
                z-index: 2;
                font-family: 'IBM Plex Serif';
                box-sizing: content-box;

                &.active {
                    color: white;
                }
            }
        }
    }

</style>