<script>
    export let widgetID;

</script>

<div class='wrap'>
    <h1>
        Widget ID Not Found.
    </h1>
    <p>ID: {widgetID}</p>
</div>

<style lang='scss'>

    .wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

</style>