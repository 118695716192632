import App from './App.svelte';
import "@fontsource/ibm-plex-serif/400.css"
import "@fontsource/ibm-plex-serif/500.css"
import "@fontsource/ibm-plex-serif/600.css"
import "@fontsource/ibm-plex-serif/700.css"
import "@fontsource/source-sans-pro/400.css"
import "@fontsource/source-sans-pro/600.css"
import "@fontsource/source-sans-pro/700.css"

const container = document.getElementById('fp_ug_widget_container');
// revert before deploy
let id = 'Error: Cannot Find Widget Container.';

// revert before deploy
if(container) {
  id = container.dataset.widgetToken;
}

new App({
  target: container,
  props: {
    // widgetID: '3f25a225-0242-4b56-b303-668e4f9d6737'
    // widgetID: 'd480c573-1785-4f0d-8de0-03aeae165736' // revert before deploy to production // Dylan's app-release widget
    // widgetID: '75e06307-a06d-40be-812a-7e3f9cef8893' // revert before deploy // Dylan's local widget
    widgetID: id
  }
});