<div class='loader-wrap'>
    <div class="load-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<style lang='scss'>

.loader-wrap {
    height: 175px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .load-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;

        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border: 8px solid #004444;
            border-radius: 50%;
            animation: load-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #004444 transparent transparent transparent;

            :nth-child(1) {
                animation-delay: -0.45s;
            }

            :nth-child(2) {
                animation-delay: -0.3s;
            }

            :nth-child(3) {
                animation-delay: -0.15s;
            }
        }
    }
}

@keyframes load-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

</style>