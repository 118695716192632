<script>
    import { onMount } from 'svelte'
    import debounce from 'debounce';
    import { cacheSelection, selectedStream, selectedTechnology, selectedUtility, categoriesByTechnology, availableManufacturers, widgetToken, sessionTimestamp } from '../store';
    import ResultsCard from '../components/ResultsCard.svelte';
    import StreamToggle from '../components/StreamToggle.svelte';
    import Modal from '../components/Modal.svelte';
    import Loader from '../components/Loader.svelte';

    let utilData;
    let alphabetizedCategories = [];
    let categoryData = [];
    let searchQuery = '';
    let modalOpen = false;
    let allManufacturerLinks = [];
    let allManufacturerLinksCategory = '';
    let allWidgetURLs = {};
    let loading = true;

    onMount(() => {
        cacheSelection();
        selectedStream.set('downstream');

        Promise.allSettled([ getUtilData(), fetchWidgetURLs() ]).then(() => {
            console.log('settled', allWidgetURLs, categoryData)
            loading = false;
        })
        try {
            fetch(`${UG_ENDPOINT}/api/widgets/setLastUsed`, {
                method: 'POST',
                body: JSON.stringify({
                    lastUsed: (new Date()).getTime() / 1000,
                    id: $widgetToken
                })
            })
            fetch(`${UG_ENDPOINT}/api/widgets/setJourney`, {
                method: 'POST',
                body: JSON.stringify({
                    timestamp: $sessionTimestamp,
                    id: $widgetToken,
                    step: 'technology'
                })
            })
        } catch (err) {
            // dont stop anything
            console.error('error tracking last used')
        }
    });

    async function fetchWidgetURLs() {
        const response = await fetch(`${API_ENDPOINT_V2}/utilities/${$selectedUtility.eiauniq}/widget_urls`)
        allWidgetURLs = await response.json()
    }

    async function getUtilData() {
        const response = await fetch(`${API_ENDPOINT_V2}/utilities/${$selectedUtility.eiauniq}/detail`);
        utilData = await response.json()

        // todo: error catching
        const categoriesByStream = $selectedStream === 'downstream' ? utilData.downstream_technologies : utilData.midstream_technologies;

        const availableCategories = categoriesByStream.filter((category) => {
            if($categoriesByTechnology && $categoriesByTechnology[$selectedTechnology]?.length > 0) {
                    return (category.technology.toLowerCase() === $selectedTechnology.toLowerCase() && !$categoriesByTechnology[$selectedTechnology].includes(category.product_category_abbr));
            } else {
                // if no categories selected in the widget dashboard, display them all
                return (category.technology.toLowerCase() === $selectedTechnology.toLowerCase());
            }
        });

        availableCategories.sort((a, b) => {
            return a.product_category_name > b.product_category_name ? 1 : -1;
        });

        alphabetizedCategories = availableCategories;
        categoryData = availableCategories;
    }

    function search() {
        // search reset
        if(searchQuery === '') {
            categoryData = alphabetizedCategories;
        }
        else {
            const filteredCategoryData = alphabetizedCategories.filter((category) => {
                return category.product_category_name.toLowerCase().includes(searchQuery.toLowerCase());
            });

            categoryData = filteredCategoryData;
        }
    }

    const handleSearch = debounce(search, 300);

    function clearSearch() {
        searchQuery = '';
        search();
    }

    function handleSeeAllManufacturers(payload) {
        allManufacturerLinks = payload.detail.links;
        allManufacturerLinksCategory = payload.detail.category;
        modalOpen = true;
    }

    function handleStreamChange(event) {
        selectedStream.set(event.detail.stream);
        getUtilData()
    }

</script>

<div>
    {#if loading}
        <Loader />
    {:else}
        <h1 class='title'>
            {utilData.name}
            <span class={`ug-badge ${utilData.program_status ? utilData.program_status.toLowerCase() : ''}`}>
                {utilData.program_status ? utilData.program_status.toUpperCase() : 'unknown'}
            </span>
        </h1>
        <div class='results-wrap'>
            <div class='card-header-wrap'>
                <div class='title'>
                    <span>showing rebates for</span>
                    <h2>{$selectedTechnology.toUpperCase()}</h2>
                </div>
                <div class='stream-switcher'>
                  <StreamToggle on:stream-change={handleStreamChange} />
                </div>
                <div class='input-wrap'>
                    <input class='category-search-input' placeholder='Search for Product Category ...' type='text'
                        bind:value={searchQuery} on:keyup={handleSearch} />
                    {#if searchQuery !== ''}
                        <span class='clear-input' on:click={clearSearch}>⊗</span>
                    {/if}
                </div>
            </div>
            <div class='results'>
                {#if categoryData.length > 0 && Object.keys(allWidgetURLs).length > 0}
                    {#each categoryData as category, index (`${index}-${category.product_category_abbr}`)}
                        <ResultsCard
                            {category}
                            {index}
                            manufacturers={$availableManufacturers}
                            widgetURLs={allWidgetURLs.manufacturers_by_product_category[category.product_category_abbr]}
                            on:see-all={handleSeeAllManufacturers}
                        />
                    {/each}
                {:else}
                    <p>No matching categories found</p>
                {/if}
            </div>
        </div>
    {/if}
    {#if modalOpen}
    <Modal width='60%' on:request-close={() => modalOpen = false}>
        <div class='ug-widget-modal-body' slot='modal-content'>
            <h1>View <span>{allManufacturerLinksCategory}</span> Products</h1>
            <div class='divider'></div>
            <small class='all-products-subtitle'>
                Visit these manufacturers to see qualified products for this category and get exact rebate amounts.
            </small>
            <div class='manufacturer-links-wrap'>
                {#each allManufacturerLinks as manufacturerLink}
                <div class='manufacturer-link-card'>
                    <div>
                        <div class='ug-border'></div>
                        <p>{manufacturerLink.name}</p>
                    </div>
                    <a href={manufacturerLink.widget_url} target='_blank'>
                        View
                        <span>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style="font-size: 14px;"><path d="m13 3 3.293 3.293-7 7 1.414 1.414 7-7L21 11V3z"></path><path d="M19 19H5V5h7l-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5l-2-2v7z"></path></svg>
                        </span>
                    </a>
                </div>
                {/each}
            </div>
        </div>
    </Modal>
    {/if}
</div>

<style lang='scss'>

    .title {
        display: flex;
        align-items: center;
        font-family: 'Source Sans Pro';
        font-weight: 400;

        .ug-badge {
            font-size: 0.85rem;
            margin-left: 10px;
            font-weight: 400;
            padding: 2px 10px;
            border-radius: 25px;
            background-color: rgb(219, 167, 39);
            color: white;
            font-family: 'IBM Plex Serif';
            line-height: 1;

            &.open {
                background-color: rgb(44, 120, 115);
                color: white;
            }

            &.closed {
                background-color: rgb(130, 9, 25);
                color: white;
            }
        }
    }

    .results-wrap {
        padding-left: 20px;
        padding-right: 20px;
        background-color: white;
        border-radius: 25px;
        padding: 20px;
        border: 1px solid #f1f0f0;
        box-shadow: 0 20px 25px -5px rgb(0, 0, 0, 0.1), 0 10px 10px -5px rgb(0, 0, 0, 0.04);

        .card-header-wrap {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .title {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-family: 'Source Sans Pro';
                font-weight: 400;

                span {
                    font-style: italic;
                    color: gray;
                    font-weight: 400;
                }

                h2 {
                    display: flex;
                    align-items: center;
                    margin: 10px 15px;
                    font-weight: 400;
                }

                .dot {
                    width: 6px;
                    height: 6px;
                    margin-left: 5px;
                    margin-right: 5px;
                    background-color: black;
                    border-radius: 50%;
                }
            }

            .input-wrap {
                width: 50%;
                position: relative;
                padding-bottom: 10px;

                .category-search-input {
                    width: 100%;
                    border-radius: 25px;
                    position: relative;
                    margin: 0;
                    padding-left: 20px;
                    padding-right: 20px;
                    background-color: transparent;
                    height: 42px;
                    font-family: 'IBM Plex Serif';
                    border: 1px solid #ddd;
                    box-sizing: border-box;

                    &:focus-visible {
                        outline: 1px solid rgb(44, 120, 115);
                        box-shadow: none;
                    }
                }

                .clear-input {
                    position: absolute;
                    right: 10px;
                    font-size: 25px;
                    color: #5c5959;
                    top: 45%;
                    transform: translateY(-50%);
                    cursor: pointer;
                }
            }

            .stream-switcher {
              padding-bottom: 10px;
            }
        }

        .results {
            max-height: 375px;
            overflow-y: scroll;
            padding: 1em 1em 2em;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 14px 3px inset;
            border-radius: 7px;

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background: lightgray;
                border-radius: 10px;
                height: 30px;
            }
        }
    }

    .ug-widget-modal-body {
        padding-left: 1em;
        padding-right: 1em;
        font-family: 'IBM Plex Serif';

        h1 {
            font-weight: 400;
            font-family: 'Source Sans Pro';

            span {
                font-weight: 600;
            }
        }

        .all-products-subtitle {
            font-style: italic;
            display: block;
            padding-left: 0.75em;
            padding-right: 0.75em;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        .divider {
            width: 60%;
            background-color: #ddd;
            height: 1px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1em;
        }

        .manufacturer-links-wrap {
            max-height: 400px;
            overflow-y: scroll;
            box-shadow: inset 0px 0px 14px 3px rgba(0,0,0,0.05);
            width: 95%;
            margin: 1em auto;
            border-radius: 7px;

            .manufacturer-link-card {
                border: 1px solid #ddd;
                border-radius: 7px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 1em;
                padding-left: 1em;
                margin: 0.5em 1em;
                position: relative;
                box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
                font-family: 'IBM Plex Serif';

                .ug-border {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    border-top-left-radius: 7px;
                    border-bottom-left-radius: 7px;
                    width: 7px;
                    background-color: #d0f2e1;
                }

                p {
                    margin: 0.75em;
                    font-weight: 600;
                }

                a {
                    cursor: pointer;
                    color: black;
                    text-decoration: underline;
                }
            }

            // custom scrollbar
            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background: lightgray;
                border-radius: 10px;
                height: 30px;
            }
        }
    }

    @media screen and (max-width: 768px) {

        .results-wrap {
            .card-header-wrap {
                flex-direction: column;
                align-items: flex-start;

                .title {
                    margin-bottom: 0;
                }

                .input-wrap {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }

        .manufacturer-link-card {
            a {
                width: 30%;
                text-align: right;
            }
        }
    }
</style>
